import CryptoJS from "crypto-js";

const handleDecrypt = (encrypted) => {
  const ENCRYPTION_KEY = "tWpWbGI7PNnxBshuDvkU2HbRFIyEY90T";
  const response = encrypted;
  const nwEncryptedDat = response.encrypted;
  const encryptedData = nwEncryptedDat.bllprxx;
  const iv = nwEncryptedDat.rxxid;
  const encryptedDataWordArray = CryptoJS.enc.Hex.parse(encryptedData);
  const ivWordArray = CryptoJS.enc.Hex.parse(iv);

  if (!encryptedDataWordArray || !ivWordArray) {
    console.error("Invalid encrypted data or IV.");
    return;
  }
  try {
    const decrypted = CryptoJS.AES.decrypt(
      {
        ciphertext: encryptedDataWordArray,
      },
      CryptoJS.enc.Utf8.parse(ENCRYPTION_KEY),
      {
        iv: ivWordArray,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );
    const decryptedData = decrypted.toString(CryptoJS.enc.Utf8);
    return decryptedData;
  } catch (error) {
    console.error("Error during decryption:", error);
  }
};
export default handleDecrypt;
