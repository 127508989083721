import { useState, useEffect } from "react";
import Modal from '@mui/material/Modal';
import Grow from '@mui/material/Grow';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import bcrypt from 'bcryptjs'

const CurrentPass = ({ openConfrmPass, handleClose, trggr, confrmPass, crrntPass }) => {
    // MUI MODAL STYLE
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 3,
        borderRadius: '10px',
    };
    // MUI MODAL STYLE

    const getpass = async(e) => {
        e.preventDefault();
        let edtpass = document.getElementById('edtpass')
        let edtpassval = edtpass.value
        bcrypt.compare(edtpassval, crrntPass, function(err, result) {
            if(result){
                confrmPass(trggr)
            }
            else{
                edtpass.style.border = '1px solid red'
            }
        })
    }

    const inputpasschngd = async(edtpass) => {
        edtpass.style.border = ''
    }

    return (
        <Modal
            open={openConfrmPass}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Grow in={openConfrmPass}>
                    <div className="card-body p-0 mt-4">
                        <div className='row'>
                            <div className='col-sm-4'>
                                <label className='mt-2'>Current Password</label>
                            </div>

                            <div className='col-sm-8'>
                                <form onSubmit={getpass}>
                                    <input type="password" required
                                    className="form-control w-100" id="edtpass"
                                    onChange={(e)=>{inputpasschngd(e.target)}}/>
                                    <small>
                                        <div className='text-xs text-muted mt-1'>
                                            Create a password that:
                                            <li>contains at least 8 characters</li>
                                            <li>contains both lower (a-z) and upper case letters (A-Z)</li>
                                            <li>contains at least one number (0-9) or a symbol</li>
                                            <li>is not commonly used</li>
                                        </div>
                                    </small>
                                    <br/>
                                    <Button variant="contained" type='submit'
                                    className='mt-3 float-right'> 
                                        SUBMIT 
                                    </Button>
                                </form>
                            </div>
                        </div>
                    </div>
                </Grow>
            </Box>
        </Modal>
    )
}

export default CurrentPass