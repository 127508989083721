import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import * as BsIcons from "react-icons/bs";
import handleDecrypt from '../functions/fx_dcrpt';
import { useNavigate } from "react-router-dom";
import { useIdleTimer } from 'react-idle-timer';

const Header = ({ url_str, url_origin }) => {

  const navigate = useNavigate();
  const location = useLocation();
  const pathloc = location.pathname

  const logout = async() => {
    fetch(`${url_str}/logoutpost`,{credentials:"include"})
    .then((response) => response.json())
    .then((responseJson) => {
      if (responseJson.responsecode === "0934") {
        return  navigate("/");
      } 
      else {
        // Nothing to do
      }
    })
    .catch((error) => {
      console.log(error)
    });
  }

  const accountsetting = async() => {
    return  navigate("/accnt_sttng");
  }
  
  const [session, setSession] = useState("");
  const checksession = async() => {
    fetch(`${url_str}/checksession`,{credentials:"include",withCredentials: true})
    .then((response) => response.json())
    .then((responseJson) => {
      const res = JSON.parse(handleDecrypt(responseJson));
      setSession(res);
      if(pathloc === '/system_urs'){
        if(res.access === 'Main Office' && res.district === 'COSDD'){
          // Do nothing
        }
        else{
          navigate(-1)
        }
      }
      else if(pathloc === '/audt_trl'){
        if(res.access === 'Main Office' && res.district === 'COSDD'){
          // Do nothing
        }
        else{
          navigate(-1)
        }
      }
    })
    .catch(() => {
        return  navigate("/");
    });
  }

  const time = 15 * 60 * 1000
  const onIdle = async() => {
    console.log('Automatically logged you out, idled for ' + time/1000/60 + ' minutes.');
    logout()
  }

  const { getRemainingTime } = useIdleTimer({
      onIdle,
      timeout: time,
  })
  
  useEffect(() => {
    checksession()
  }, [])

  return (
    <nav className="main-header navbar navbar-expand navbar-white navbar-light"> 
      <ul className="navbar-nav">
        <li className="nav-item">
          <button className="nav-link" 
          data-widget="pushmenu" href="#" 
          style={{backgroundColor: "transparent", border: "none"}}>
            <BsIcons.BsList className='text-lg'/>
          </button>
        </li>
      </ul>
      <ul className="navbar-nav ml-auto">
        <li className="nav-item dropdown">
          <span data-toggle="dropdown">
            <span className="nav-link" 
            style={{cursor:'pointer'}}>
                <div className='font-weight-bold'>
                  {session?session.fullname:''}
                </div>
            </span>
          </span>

          <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
            <div className='card p-3 m-3 text-center' style={{backgroundColor: "#e8f1fa"}}>
              <div className="w-100" style={{lineHeight: "17px"}}>
                <span className="username">
                  <p className='font-weight-bold'>
                    {session?session.fullname:''}
                  </p>
                </span>
                <small className="description text-dark">
                    {session?session.email:''}
                </small>
              </div>
            </div>
            
            <div>
              <div className="dropdown-divider"></div>
              <button className="dropdown-item p-3" onClick={accountsetting}>
                <BsIcons.BsGear /><span className='float-right'>Manage Account</span>
              </button>
              <div className="dropdown-divider"></div>
              <button className="dropdown-item p-3" onClick={logout}>
                <BsIcons.BsArrowReturnRight  /><span className='float-right'>Sign out</span>
              </button>
            </div>
          </div>
        </li>
      </ul>
    </nav>
  )
}

export default Header