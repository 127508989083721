import { useState, useEffect } from 'react'
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import * as BsIcons from "react-icons/bs";
// MUI
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// TOAST
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Profile from "../dist/img/user.png"
import handleDecrypt from '../functions/fx_dcrpt';
import CurrentPass from '../modals/users/CurrentPass';
import {updte_usr_pass, updte_usr_email} from '../functions/fx_user';
import bcrypt from 'bcryptjs'


const AccountSetting = ({ url_str, url_origin }) => {

    // HEADERS
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    headers.append("Origin", `${url_origin}`);
    // HEADERS

    // MODAL CONFIRM PASSWORD FOR NEW PASSWORD SUBMIT
    const [openConfrmPass, setOpenConfrmPass] = useState(false);
    const handleOpen = () => setOpenConfrmPass(true);
    const handleClose = () => setOpenConfrmPass(false);
    const [trggr, setTrggr] = useState('');
    
    const mailModal = async(event) => {
        event.preventDefault();
        handleOpen();
        setTrggr('email');
    }

    const passModal = async(event) => {
        event.preventDefault();
        handleOpen();
        setTrggr('password');
        
    }
    // MODAL CONFIRM PASSWORD FOR NEW PASSWORD SUBMIT
    
    const [newEmail, setNewEmail] = useState('');
    const [newPass, setNewPass] = useState('');

    const confrmPass = async(trggr) => {
        if(trggr === 'password'){
            submitNewPass()
        }
        else if(trggr === 'email'){
            submitNewEmail()
        }
    }

    const submitNewPass = async() => {
        bcrypt.hash(newPass, 10, function(err, hash) {
            let res = updte_usr_pass(
                session.id,hash,url_str,url_origin
            )
            res.then((val) => {
                if (val === "Success") {
                    setNewPass('')
                    handleClose()
                    window.setTimeout(() => {
                        toast.success("Account password changes saved.")
                    }, 200)
                } 
                else {
                    window.setTimeout(() => {
                        toast.error("Failed to change user account password.")
                    }, 200)
                }
            })
        })
    }
    
    const submitNewEmail = async() => {
        let res = updte_usr_email(
            session.id,newEmail,url_str,url_origin
        )
        res.then((val) => {
            if (val === "Success") {
                setNewEmail('')
                handleClose()
                window.setTimeout(() => {
                    toast.success("Account password changes saved.")
                }, 200)
            } 
            else {
                window.setTimeout(() => {
                    toast.error("Failed to change user account password.")
                }, 200)
            }
        })
    }

    // AVATAR DISPLAY
    function stringToColor(string) {
        let hash = 0;
        let i;
    
        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }
    
        let color = '#';
    
        for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */
    
        return color;
    }
    
    function stringAvatar(fname,lname) {
        if(fname){
            return {
                sx: {
                bgcolor: stringToColor(fname+lname),
                width: "100px",
                height: "100px",
                fontSize: "300%",
                },
                children: `${fname.charAt(0)}${lname.charAt(0)}`,
            }
        }
        else{
            // Do Nothing
        }
    }
    // AVATAR DISPLAY

    const [session, setSession] = useState("");
    const checksession = async() => {
        fetch(`${url_str}/checksession`,{credentials:"include"})
        .then((response) => response.json())
        .then((responseJson) => {
            const res = JSON.parse(handleDecrypt(responseJson));
            setSession(res);
        })
        .catch(() => {
            // return  navigate("/");
        });
      }
      
    useEffect(() => {
        checksession()
    }, [])
    
    return (
    <div>
        <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            draggable
            pauseOnHover={false}
            theme="light"
        />
        <Sidebar/>
        <Header url_str={url_str} url_origin={url_origin} />

        <div className="content-wrapper pl-2 pr-2 pb-5">
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6">
                        <h1>Manage Account</h1>
                        </div>
                    </div>
                </div>
            </section>
            <section className="content m-2">
                <div className='row'>
                    <div className='col-sm-4'>
                        <div className='card'>
                            <div className='card-header card-title border-0 font-weight-bold text-center p-4' style={{lineHeight: "10px"}}>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px'}}>
                                    <Avatar {
                                        ...stringAvatar(
                                            session.firstname!=undefined?session.firstname:'',
                                            session.lastname!=undefined?session.lastname:'')
                                    }/>
                                </div>

                                <Typography id="modal-modal-title" className="mt-2" variant="h5" component="h2">
                                    {session.lastname}, {session.firstname} {session.middlename}
                                </Typography>
                            </div>
                            <div className='card-body'>
                                <ul className="list-group list-group-unbordered mb-3">
                                    <li className="list-group-item">
                                        <b>Email Address</b> 
                                        <span className="text-primary float-right">
                                            {session.email}
                                        </span>
                                    </li>
                                    <li className="list-group-item">
                                        <b>Employee No.</b> 
                                        <span className="text-primary float-right">
                                            {session.empno}
                                        </span>
                                    </li>
                                    <li className="list-group-item">
                                        <b>Access Level</b> 
                                        <span className="text-primary float-right">
                                            {session.access}
                                        </span>
                                    </li>
                                    <li className="list-group-item">
                                        <b>
                                            {session.access === 'Main Office'
                                            ?'Office'
                                            :'Region'}
                                        </b> 
                                        <span className="text-primary float-right">
                                            {session.ro_desc}
                                        </span>
                                    </li>
                                    <li className="list-group-item">
                                        <b>
                                            {session.access === 'Main Office'
                                            ?'Department'
                                            :'District'}
                                        </b> 
                                        <span className="text-primary float-right">
                                            {session.access === 'Main Office'
                                            ?session.district
                                            :session.do_desc}
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-8'>
                        <div className='card'>
                            <div className='card-header'>
                                <div className='card-title font-weight-bold'>
                                        <BsIcons.BsGear className='mb-1 mr-1'/> Update Account
                                </div>
                            </div>
                            <div className='card-body pl-5 pr-5 mt-4'>
                                <div className="card card-default collapsed-card elevation-0">
                                    <div className="card-header p-0 pb-2">
                                        <h3 className="card-title">Email Address</h3>
                                        <div className="card-tools">
                                            <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                <BsIcons.BsPencilSquare />
                                            </button>
                                        </div>
                                    </div>

                                    <div className="card-body p-0 mt-4">
                                        <div className='row'>
                                            <div className='col-sm-4'>
                                                <label className='mt-2'>New Email Address</label>
                                            </div>

                                            <div className='col-sm-8'>
                                                <form onSubmit={mailModal}>
                                                    <input type="email" className="form-control w-100" id="email" required
                                                    // pattern='\b^.+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b'
                                                    value={newEmail}
                                                    onChange={(e)=>{setNewEmail(e.target.value)}}/>
                                                    <small>Please use an active and corporate email</small><br/>
                                                    <Button variant="contained" className='mt-3 float-right'
                                                    type='submit' > 
                                                        SAVE CHANGES 
                                                    </Button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                                <div className="card card-default collapsed-card elevation-0 pt-2">
                                    <div className="card-header p-0 pb-2">
                                        <h3 className="card-title">Password</h3>
                                        <div className="card-tools">
                                            <button type="button" className="btn btn-tool" 
                                            data-card-widget="collapse">
                                                <BsIcons.BsPencilSquare />
                                            </button>
                                        </div>
                                    </div>

                                    <div className="card-body p-0 mt-4">
                                        <div className='row'>
                                            <div className='col-sm-4'>
                                                <label className='mt-2'>New Password</label>
                                            </div>

                                            <div className='col-sm-8'>
                                                <form onSubmit={passModal}>
                                                    <input type="password" className="form-control w-100" id="pass" required
                                                    pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@-_.])[A-Za-z\d@-_.]{8,25}$"
                                                    value={newPass}
                                                    onChange={(e)=>{setNewPass(e.target.value)}}/>
                                                    <small>
                                                        <div className='text-xs text-muted mt-1'>
                                                            Create a password that:
                                                            <li>contains at least 8 characters</li>
                                                            <li>contains both lower (a-z) and upper case letters (A-Z)</li>
                                                            <li>contains at least one number (0-9)</li>
                                                            <li>contains at least one special character (@ - _ .)</li>
                                                        </div>
                                                    </small>
                                                    <br/>
                                                    <Button variant="contained" className='mt-3 float-right'
                                                    type='submit' > 
                                                        SAVE CHANGES 
                                                    </Button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <CurrentPass
                openConfrmPass = {openConfrmPass}
                handleClose = {handleClose}
                trggr = {trggr}
                confrmPass = {confrmPass}
                crrntPass = {session.password}
                />

            </section>
        </div>
    </div>
  )
}

export default AccountSetting