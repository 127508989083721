import { useState, useEffect } from 'react'
import * as BsIcons from "react-icons/bs";
import NHALogo from "../dist/img/nha-logo.png";
import { NavLink, Outlet } from 'react-router-dom';


const Sidebar = () => {
    let access =localStorage.getItem("access")
    let district =localStorage.getItem("district")

    let navItems = [
        {
            path:"/name_srch",
            name:"Name Search",
            icon:<BsIcons.BsFiles className="nav-icon mb-1"/>
        },
        {
            path:"/excl_srch",
            name:"Excel Search",
            icon:<BsIcons.BsFiletypeXls className="nav-icon mb-1"/>
        },
        {
            path:"/audt_trl",
            name:"Audit Trail",
            icon:<BsIcons.BsFolder2Open className="nav-icon mb-1"/>
        },
        {
            path:"/system_urs",
            name:"System Users",
            icon:<BsIcons.BsPeople className="nav-icon mb-1"/>
        },
    ]

    return (
    <div>
        <aside className="main-sidebar sidebar-light-default elevation-2" style={{bottom: "0", position: "fixed"}}>
            <a href="https://nha.gov.ph/" className="brand-link" target="_blank" rel="noreferrer">
                <img src={NHALogo} alt="NHA Logo" className="brand-image img-circle elevation-2" style={{opacity: '.8'}} />
                <span className="brand-text">Remittance List</span>
            </a>
            <div className="sidebar">
                <nav className="mt-2">
                    <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                        {navItems.map((item, index) => {
                            if(item.name === 'Audit Trail'){
                                // Do nothing
                            }
                            else if(item.name != 'Audit Trail'){
                                if(item.name === 'System Users'){
                                    if(access === 'Main Office' && district === 'COSDD'){
                                        return (
                                        <li className="nav-item" key={index}>
                                            <NavLink to={item.path} className="nav-link" id="btn-sidebar-custom">
                                                {item.icon}
                                                <p>{item.name}</p>
                                            </NavLink>
                                        </li>
                                        )
                                    }
                                    else{
                                        // Do nothing
                                    }
                                }
                                else if(item.name != 'System Users'){
                                    return (
                                    <li className="nav-item" key={index}>
                                        <NavLink to={item.path} className="nav-link" id="btn-sidebar-custom">
                                            {item.icon}
                                            <p>{item.name}</p>
                                        </NavLink>
                                    </li>
                                    )
                                }
                            }
                        })}
                    </ul>
                </nav>
            </div>
        </aside>
    </div>
  )
}

export default Sidebar