import { useState, useEffect } from "react";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import * as BsIcons from "react-icons/bs";
// MUI
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// TOAST
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField'; 
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import handleDecrypt from '../functions/fx_dcrpt';
import {insrt_usr, updte_usr} from '../functions/fx_user';
import bcrypt from 'bcryptjs'
import { v4 } from 'uuid';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Grow from '@mui/material/Grow';
import Pagination from "../table/Pagination";

const SystemUsers = ({ url_str, url_origin }) => {

// MUI MODAL STYLE
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 3,
    borderRadius: '10px',
    padding: '0px'
};
const selectLabelStyle = {
    fontWeight: 'normal',
};
const labelStyle = {
    fontWeight: 'normal',
};
// MUI MODAL STYLE

// AVATAR DISPLAY
function stringToColor(string) {
    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
  
    return color;
}
  
function stringAvatar(fname,lname) {
    if(fname){
        return {
            sx: {
              bgcolor: stringToColor(fname+lname),
              width: "100px",
              height: "100px",
              fontSize: "300%",
            },
            children: `${fname.charAt(0)}${lname.charAt(0)}`,
        }
    }
    else{
        // Do Nothing
    }
}
// AVATAR DISPLAY

// TEXTFIELD PATTERN
const psswrdPttrn = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@-_.])[A-Za-z\d@-_.]{8,25}$/;
// TEXTFIELD PATTERN

// PAGINATION
const [load, setLoad] = useState(false);

const [dataRaw, setDataRaw] = useState([]);
const [totPgs, setTotPgs] = useState(0);
const NumPerPage = 5;
const [totRowCnt, setTotRowCnt] = useState('');
const [rowCnt, setRowCnt] = useState('');
const [frstIndx, setFrstIndx]= useState(1)

const setSlice = async(num) => {
    const last = (num * NumPerPage);
    const first = last - NumPerPage;
    let currentData = dataRaw.slice(first, last);
    setUserData(currentData);
    setRowCnt(currentData.length)
    setFrstIndx(first)
}
// PAGINATION

// SEARCH DATA TABLE
const handleSrch = async(val) => {
    const results = (dataRaw.filter((data) =>
        data.user_lname.toLowerCase().includes(
            val.toLowerCase()
        )
    ))
    setTotRowCnt(results.length);
    let totalpages = Math.ceil(results.length / NumPerPage);
    setTotPgs(totalpages)
    const last = (1 * NumPerPage);
    const first = last - NumPerPage;
    let currentData = results.slice(first, last);
    setRowCnt(currentData.length)
    setUserData(currentData)
    setFrstIndx(first)
}
// SEARCH DATA TABLE

// MODAL USER DATA
const [mdlUsrData, setMdlUsrData] = useState([]);
// MODAL USER DATA

// MUI SELECT
const [accessLevel, setAccessLevel] = useState('');
const [region, setRegion] = useState('');
const [district, setDistrict] = useState(''); 
// MUI SELECT

// INPUT VALUE
const [lname, setLname] = useState('');
const [fname, setFname] = useState('');
const [mname, setMname] = useState('');
const [email, setEmail] = useState('');
const [empno, setEmpno] = useState('');
const [password, setPassword] = useState('');
// INPUT VALUE

const handleChangeAccessLevel = (event) => {
    setAccessLevel(event.target.value);
    if(event.target.value === 'Main Office'){
        setRegion('20')
        setDistrict('')
    }
    else if(event.target.value === 'Region'){
        setRegion('')
        setDistrict('not applicable')
    }
    else if(event.target.value === 'District'){
        setRegion('')
        setDistrict('')
    }
};

const handleChangeRegion= (event) => {
    setRegion(event.target.value);
    if(accessLevel != 'Region'){
        setDistrict('')
    }
};

const handleChangeDistrict= (event) => {
    setDistrict(event.target.value);
};

const [sbmtAdd, setUsrAdd] = useState(false);
const [colorAdd, setColorAdd] = useState('');
const [emailLbl, setEmailLbl] = useState('*Please use an active e-mail address');
const handleEmailVldty = (event, val) => {
    const filtdataemail = (dataRaw.filter(data => {
        return data.user_email === val
    }))

    if(filtdataemail.length > 0){
        setUsrAdd(true)
        setColorAdd('red')
        setEmailLbl('Email address already exists')
        event.target.setCustomValidity('Invalid entry. Input other e-mail address.');
    }
    else if(filtdataemail.length === 0){
        setUsrAdd(false)
        setColorAdd('')
        setEmailLbl('*Please use an active e-mail address')
        event.target.setCustomValidity('');
    }
}

// STEPPER
const [currentStep, setCurrentStep] = useState(1);
// STEPPER

const handleSubmitIntl = async(e) => {
    e.preventDefault();
    if(colorAdd === 'red'){
        document.getElementById('email').reportValidity();
    }
    else{
        setCurrentStep(currentStep + 1)
    }
}

const handleSubmit = async(e) => {
    e.preventDefault();
    let txtid = v4();
    bcrypt.hash(password, 10, function(err, hash) {
        let res = insrt_usr(
            txtid,lname,fname,mname,
            email,empno,accessLevel,region,
            district,hash,
            url_str,url_origin
        )
        res.then((val) => {
            if (val === "Success") {
                setAccessLevel('')
                setRegion('')
                setDistrict('')
                setLname('')
                setFname('')
                setMname('')
                setEmail('')
                setEmpno('')
                setPassword('')
                setCurrentStep(1)
                setColorAdd('')
                setEmailLbl('*Please use an active e-mail address')
                window.setTimeout(() => {
                    toast.success("New user account created.")
                }, 200)
            } 
            else {
                window.setTimeout(() => {
                    toast.error("Failed to add new user account.")
                }, 200)
            }
            fetchdata()
        })
    })
};

// ============================= EDIT MODAL =============================
// MODAL EDIT INFO
const [openEditModal, setOpenEdt] = useState(false);
const handleOpenEdt = (data) => {
    setOldpassEdt(data.user_password)
    setOpenEdt(true)
    setCurrentStepEdt(1)
    setMdlUsrData(data)
    setUserIdEdt(data.user_id)
    setLnameEdt(data.user_lname)
    setFnameEdt(data.user_fname)
    setMnameEdt(data.user_mname)
    setEmailEdt(data.user_email)
    setEmpnoEdt(data.user_empno)
    setPasswordEdt(data.user_password)
    setAccessLevelEdt(data.user_access)
    setRegionEdt(data.user_region)
    setDistrictEdt(data.user_district)
    setStatusEdt(data.user_status)
    setColorAddEdt('')
    setEmailLblEdt('*Please use an active e-mail address')
};
const handleCloseEdt = () => setOpenEdt(false);
// MODAL EDIT INFO

// STEPPER
const [currentStepEdt, setCurrentStepEdt] = useState(1);
// STEPPER

// MUI SELECT
const [accessLevelEdt, setAccessLevelEdt] = useState('');
const [regionEdt, setRegionEdt] = useState('');
const [districtEdt, setDistrictEdt] = useState(''); 
const [statusEdt, setStatusEdt] = useState(''); 
// MUI SELECT

// INPUT VALUE
const [userIdEdt, setUserIdEdt] = useState('');
const [lnameEdt, setLnameEdt] = useState('');
const [fnameEdt, setFnameEdt] = useState('');
const [mnameEdt, setMnameEdt] = useState('');
const [emailEdt, setEmailEdt] = useState('');
const [empnoEdt, setEmpnoEdt] = useState('');
const [oldpassEdt, setOldpassEdt] = useState('');
const [passwordEdt, setPasswordEdt] = useState('');
// INPUT VALUE

// HANDLE DROP DOWN OPTIONS
const handleChangeAccessLevelEdt = (event) => {
    setAccessLevelEdt(event.target.value);
    if(event.target.value === 'Main Office'){
        setRegionEdt('20')
        setDistrictEdt('')
    }
    else if(event.target.value === 'Region'){
        setRegionEdt('')
        setDistrictEdt('not applicable')
    }
    else if(event.target.value === 'District'){
        setRegionEdt('')
        setDistrictEdt('')
    }
};

const handleChangeRegionEdt= (event) => {
    setRegionEdt(event.target.value);
    if(accessLevelEdt != 'Region'){
        setDistrictEdt('')
    }
};

const handleChangeDistrictEdt= (event) => {
    setDistrictEdt(event.target.value);
};

const handleChangeStatusEdt= (event) => {
    setStatusEdt(event.target.value);
};
// HANDLE DROP DOWN OPTIONS

const [sbmtAddEdt, setUsrAddEdt] = useState(false);
const [colorAddEdt, setColorAddEdt] = useState('');
const [emailLblEdt, setEmailLblEdt] = useState('*Please use an active e-mail address');
const handleEmailVldtyEdt = (event, val) => {
    const filtdataemail = (dataRaw.filter(data => {
        return data.user_email === val
    }))
    
    if(filtdataemail.length > 0){
        if(mdlUsrData.user_email === val){
            setUsrAddEdt(false)
            setColorAddEdt('')
            setEmailLblEdt('*Please use an active e-mail address')
            event.target.setCustomValidity('');
        }
        else{
            setUsrAddEdt(true)
            setColorAddEdt('red')
            setEmailLblEdt('Email address already exists')
            event.target.setCustomValidity('Invalid entry. Input other e-mail address.');
        }
    }
    else if(filtdataemail.length === 0){
        setUsrAddEdt(false)
        setColorAddEdt('')
        setEmailLblEdt('*Please use an active e-mail address')
        event.target.setCustomValidity('');
    }
}

const handleSubmitEdtIntl = async(e) => {
    e.preventDefault();
    if(colorAddEdt === 'red'){
        document.getElementById('emailEdt').reportValidity();
    }
    else{
        setCurrentStepEdt(currentStepEdt + 1)
    }
}

const handleSubmitEdt = async(e) => {
    e.preventDefault();
    let res = updte_usr(
        userIdEdt,lnameEdt,fnameEdt,mnameEdt,
        emailEdt,empnoEdt,accessLevelEdt,regionEdt,
        districtEdt,statusEdt,
        url_str,url_origin
    )
    res.then((val) => {
        if (val === "Success") {
            setAccessLevelEdt('')
            setRegionEdt('')
            setDistrictEdt('')
            setLnameEdt('')
            setFnameEdt('')
            setMnameEdt('')
            setEmailEdt('')
            setEmpnoEdt('')
            setPasswordEdt('')
            setStatusEdt('')
            setCurrentStepEdt(1)
            setColorAddEdt('')
            setEmailLblEdt('*Please use an active e-mail address')
            window.setTimeout(() => {
                toast.success("User account changes saved.")
            }, 200)
        } 
        else {
            window.setTimeout(() => {
                toast.error("Failed to change user account information.")
            }, 200)
        }
        handleCloseEdt()
        fetchdata()
    })
}
// ============================= EDIT MODAL =============================

// FETCH DATA
const [userData, setUserData] = useState([]);
const [regData, setRegData] = useState([]);
const [distData, setDistData] = useState([]);
const fetchdata = async() => {
    setLoad(true)
    const response = await fetch(`${url_str}/alphaListingUsers`, { 'Content-Type': 'application/json', 'credentials': 'include' });
    const encrptdata = await response.json();
    const dataValue = handleDecrypt(encrptdata);
    const data = await JSON.parse(dataValue);
    setDataRaw(data.rows);
    setTotRowCnt(data.rows.length);
    let totalpages = Math.ceil(data.rows.length / NumPerPage);
    setTotPgs(totalpages)
    const last = (1 * NumPerPage);
    const first = last - NumPerPage;
    let currentData = data.rows.slice(first, last);
    setRowCnt(currentData.length)
    setUserData(currentData)
    setFrstIndx(first)
    setLoad(false);
}

const fetchRegion = async() => {
    const response = await fetch(`${url_str}/usrRegOffc`, { 'Content-Type': 'application/json', 'credentials': 'include' });
    const encrptdata = await response.json();
    const dataValue = handleDecrypt(encrptdata);
    const data = await JSON.parse(dataValue);
    setRegData(data.rows)
}

const fetchDistrict = async() => {
    const response = await fetch(`${url_str}/usrDistOffc`, { 'Content-Type': 'application/json', 'credentials': 'include' });
    const encrptdata = await response.json();
    const dataValue = handleDecrypt(encrptdata);
    const data = await JSON.parse(dataValue);
    setDistData(data.rows)
}
// FETCH DATA

useEffect(() => {
    fetchdata()
    fetchRegion()
    fetchDistrict()
}, [])

return (
    <div>
        <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            draggable
            pauseOnHover={false}
            theme="light"
        />
        <Sidebar />
        <Header url_str={url_str} url_origin={url_origin} />

        <div className="content-wrapper pl-2 pr-2 pb-5">
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6">
                        <h1>System Users</h1>
                        </div>
                    </div>
                </div>
            </section>
            <section className="content">
                <div className='row p-2' id='row'>
                    <div className='col-sm-4'>
                        <div className='card'> 
                            <div className='card-header'>
                                <div className='card-title font-weight-bold'>ADD SYSTEM USER</div>
                            </div>

                            <div className='card-body'>
                                <form onSubmit={handleSubmitIntl}>
                                    {currentStep === 1 && (
                                        <Grow in={currentStep === 1}>
                                            <div>
                                                <h5><BsIcons.BsPeople className='mb-1'/> User Information</h5>
                                                <Divider/>
                                                <br/>
                                                <TextField id="lname" 
                                                label="Last Name" variant="outlined" required
                                                size="small" fullWidth InputLabelProps={{ style: labelStyle }}
                                                type="text" name="field1" value={lname} 
                                                onChange={(e)=>{setLname(e.target.value)}} />

                                                <TextField className="mt-3" id="fname" 
                                                label="First Name" variant="outlined" required
                                                size="small" fullWidth InputLabelProps={{ style: labelStyle }}
                                                type="text" name="field2" value={fname} 
                                                onChange={(e)=>{setFname(e.target.value)}} /> 

                                                <TextField className="mt-3" id="mname" 
                                                label="Middle Name" variant="outlined" required
                                                size="small" fullWidth InputLabelProps={{ style: labelStyle }}
                                                type="text" name="field3" value={mname} 
                                                onChange={(e)=>{setMname(e.target.value)}} />    

                                                <TextField className="mt-3" id="email" 
                                                sx={{
                                                    '& .MuiOutlinedInput-root': {
                                                      '& fieldset': {
                                                        borderColor: colorAdd,
                                                      },
                                                    },
                                                }}
                                                label="Email Address" variant="outlined" required
                                                size="small" fullWidth InputLabelProps={{ style: labelStyle }}
                                                type="text" name="field4" value={email} 
                                                onChange={(e)=>{setEmail(e.target.value)}}
                                                onChangeCapture={(e)=>{handleEmailVldty(e, e.target.value)}} />  
                                                <p className='text-xs float-right' style={{color:colorAdd}}>
                                                    {emailLbl}
                                                </p>

                                                <TextField className="mb-2" id="empno" 
                                                label="Employee No." variant="outlined" required
                                                size="small" fullWidth InputLabelProps={{ style: labelStyle }}
                                                type="text" name="field4" value={empno} 
                                                onChange={(e)=>{setEmpno(e.target.value)}} />  
                                                
                                            </div>
                                        </Grow>
                                    )}

                                    {currentStep < 2 && (
                                        <div className='mt-4'>
                                            <Button variant="outlined" onClick={() => setCurrentStep(currentStep - 1)} 
                                            disabled={currentStep === 1 ? true : false}>
                                                Previous
                                            </Button> &nbsp;
                                            <Button variant="contained" className='w-25' 
                                            type='submit'>
                                                Next
                                            </Button>
                                        </div>
                                    )}
                                </form>

                                <form onSubmit={handleSubmit}>
                                    {currentStep === 2 && (
                                        <Grow in={currentStep === 2}>
                                            <div>
                                                <h5><BsIcons.BsGear className='mb-1'/> Account Information</h5>
                                                <Divider/>
                                                <br/>
                                                <FormControl size="small" fullWidth>
                                                    <InputLabel id="demo-simple-select-label" style={selectLabelStyle}>Access Level *</InputLabel>
                                                    <Select required
                                                        labelId=""
                                                        id=""
                                                        value={accessLevel}
                                                        label="Access Level"
                                                        onChange={handleChangeAccessLevel}
                                                    >
                                                        <MenuItem value="Main Office">Main Office Access</MenuItem>
                                                        <MenuItem value="Region">Region Office Access</MenuItem>
                                                        <MenuItem value="District">District Office Access</MenuItem>
                                                    </Select>
                                                </FormControl>

                                                <FormControl size="small" className="mt-3" fullWidth
                                                disabled={accessLevel === 'Main Office'}>
                                                    <InputLabel id="demo-simple-select-label" style={selectLabelStyle}>
                                                        {accessLevel === 'Main Office'?'Office *':'Region *'}
                                                    </InputLabel>
                                                    <Select required
                                                        labelId=""
                                                        id=""
                                                        value={region}
                                                        label={accessLevel === 'Main Office'?'Office':'Region'}
                                                        onChange={handleChangeRegion}
                                                    >
                                                        {regData.map((dt)=>{
                                                            if(dt.id === '20' && accessLevel != 'Main Office'){
                                                                return <MenuItem key={dt.id} value={dt.id} hidden>
                                                                    {dt.ro_desc}
                                                                </MenuItem>
                                                            }
                                                            else{
                                                                return <MenuItem key={dt.id} value={dt.id}>{dt.ro_desc}</MenuItem>
                                                            }
                                                        })}
                                                    </Select>
                                                </FormControl>

                                                <FormControl size="small" className="mt-3" fullWidth>
                                                    <InputLabel id="demo-simple-select-label" style={selectLabelStyle}>
                                                        {accessLevel === 'Main Office'?'Department *':'District *'}
                                                    </InputLabel>
                                                    <Select required
                                                        labelId=""
                                                        id=""
                                                        value={district}
                                                        label={accessLevel === 'Main Office'?'Department':'District'}
                                                        onChange={handleChangeDistrict}
                                                        disabled={accessLevel === 'Region'}
                                                    >
                                                        <MenuItem value='not applicable' hidden>
                                                            Not Applicable
                                                        </MenuItem>
                                                        <MenuItem value='ACCOUNTING' hidden={accessLevel != 'Main Office'}>
                                                            ACCOUNTING
                                                        </MenuItem>
                                                        <MenuItem value='COSDD' hidden={accessLevel != 'Main Office'}>
                                                            COSDD
                                                        </MenuItem>

                                                        {distData.map((dt)=>{
                                                            if(region === dt.ro_id){
                                                                return <MenuItem key={dt.id} value={dt.id}>{dt.do_desc}</MenuItem>
                                                            }
                                                            else{
                                                                // Do nothing
                                                            }
                                                        })}
                                                    </Select>
                                                </FormControl>

                                                
                                                <TextField className="mt-3" id="outlined-basic" 
                                                label="Password" required
                                                variant="outlined" size="small" 
                                                // pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@-_.])[A-Za-z\d@-_.]{8,25}$"
                                                fullWidth InputLabelProps={{ style: labelStyle }}
                                                type="password" name="field5" value={password} 
                                                inputProps={{
                                                    pattern: psswrdPttrn.source,
                                                }}
                                                onChange={(e)=>{setPassword(e.target.value)}} /> 
                                                <div className='text-xs text-muted mt-1'>
                                                    Create a password that:
                                                    <li>contains at least 8 characters</li>
                                                    <li>contains both lower (a-z) and upper case letters (A-Z)</li>
                                                    <li>contains at least one number (0-9)</li>
                                                    <li>contains at least one special character (@ - _ .)</li>
                                                </div>
                                            </div>
                                        </Grow>
                                    )}

                                    {currentStep === 2 && (
                                        <div className='mt-4'>
                                            <Button variant="outlined" onClick={() => setCurrentStep(currentStep - 1)}>
                                                Previous
                                            </Button> &nbsp;
                                            <Button variant="contained" 
                                            className='w-25' type='submit'
                                            disabled={sbmtAdd}>
                                                Submit
                                            </Button>
                                        </div>
                                    )}
                                </form>
                            </div>
                        </div>
                    </div>

                    <div className='col-sm-8'>  
                        <div className="card">
                            <div className="card-header border-0 mt-1 item-wrap">
                                <h3 className="card-title font-weight-bold">LIST OF SYSTEM USERS</h3>
                                <div className="card-tools">
                                    <div className="input-group input-group-sm" style={{width: 200}}>
                                        <input type="text" name="table_search" 
                                        className="form-control float-right" placeholder="Search"
                                        onChange={(e)=>{handleSrch(e.target.value)}} />
                                        <div className="input-group-append">
                                        <button type="submit" className="btn btn-default">
                                            <BsIcons.BsSearch/>
                                        </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body table-responsive p-0">
                                <table className="table table-head-fixed table-hover text-wrap">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Full Name</th>
                                        <th>Email address</th>
                                        <th className='text-center'>Access Level</th>
                                        <th className='text-center'>Action</th>
                                    </tr>
                                </thead>
                                <tbody id={load?'loading-table':''}>
                                    {userData.length>0
                                    ?userData.map((data, index) => (
                                        <tr key={data.user_id}>
                                            <td>{index + 1 + frstIndx}</td>
                                            <td>{data.user_lname}, {data.user_fname} {data.user_mname}</td>
                                            <td>{data.user_email}</td>
                                            <td className='text-center'>
                                                {
                                                data.user_status === 'Active'
                                                ?<label className='badge badge-active w-100'>{data.user_access} Access</label>
                                                :<label className='badge badge-error w-100'>{data.user_access} Access</label>
                                                }
                                            </td>
                                            <td className='text-center' style={{display: "flex", justifyContent: "center"}}>
                                                <IconButton className='nav-item' 
                                                color="primary" size="small"
                                                onClick={(e)=>{handleOpenEdt(data);}}> 
                                                    <BsIcons.BsEye /> 
                                                </IconButton>
                                            </td>
                                        </tr>
                                    ))
                                    :<tr>
                                        <td colSpan={5} className='text-center'>&nbsp;<i hidden={load}> No Available Data </i>&nbsp;</td>
                                    </tr>}
                                </tbody>
                                </table>
                            </div>

                            {totPgs < 2
                                ?<div></div>
                                :<Pagination
                                totalPagesOut = {totPgs}
                                rowCountOut = {rowCnt}
                                setslice = {setSlice}
                                totalrowCount = {totRowCnt}
                                numPerPage = {NumPerPage}
                            />}
                        </div>
                    </div>
                </div>

                <Modal
                    open={openEditModal}
                    onClose={handleCloseEdt}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style} id='modal-mobile'>
                        {currentStepEdt === 1 && (
                            <Grow in={currentStepEdt === 1}>
                                <div className="card-body">
                                    <div className='text-center mt-2'>
                                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                            <Avatar {...stringAvatar(mdlUsrData.user_fname,mdlUsrData.user_lname)}/>
                                        </div>

                                        <Typography id="modal-modal-title" className="mt-2" variant="h5" component="h2">
                                        {mdlUsrData.user_lname}, {mdlUsrData.user_fname} {mdlUsrData.user_mname}
                                        </Typography>
                                        
                                        <Button variant="outlined" className='mt-3 pl-4 pr-4' 
                                        onClick={() => setCurrentStepEdt(currentStepEdt + 1)}
                                        sx={{borderRadius:'20px', textTransform:'none'}}
                                        >
                                            <BsIcons.BsPencilFill /> &nbsp; Manage User Account
                                        </Button>
                                    </div>
                                    <div className='mt-4'>
                                        <ul className="list-group list-group-unbordered mb-3">
                                            <li className="list-group-item">
                                                <b>Email</b> 
                                                <span className="float-right text-primary">
                                                    {mdlUsrData.user_email}
                                                </span>
                                            </li>
                                            <li className="list-group-item">
                                                <b>Employee Number</b> 
                                                <span className="float-right text-primary">
                                                    {mdlUsrData.user_empno}
                                                </span>
                                            </li>
                                            <li className="list-group-item">
                                                <b>Access Level</b> 
                                                <span className="float-right text-primary">
                                                    {mdlUsrData.user_access}
                                                </span>
                                            </li>
                                            <li className="list-group-item">
                                                <b>
                                                    {mdlUsrData.user_access === 'Main Office'
                                                    ?'Office'
                                                    :'Region Office'}
                                                </b> 
                                                <span className="float-right text-primary">
                                                    {mdlUsrData.ro_desc}
                                                </span>
                                            </li>
                                            <li className="list-group-item" hidden={mdlUsrData.user_access === 'Region'}>
                                                <b>
                                                    {mdlUsrData.user_access === 'Main Office'
                                                    ?'Department'
                                                    :'District'}
                                                </b> 
                                                <span className="float-right text-primary">
                                                    {mdlUsrData.user_access === 'Main Office'
                                                    ?mdlUsrData.user_district
                                                    :mdlUsrData.do_desc}
                                                </span>
                                            </li>
                                        </ul>
                                    </div> 
                                </div>
                            </Grow>
                        )}
                        {currentStepEdt > 1 && (
                            <Grow in={currentStepEdt > 1}>
                                <div> 
                                    <div className='card-header'>
                                        <div className='card-title font-weight-bold'>EDIT SYSTEM USER</div>
                                    </div>
                                    
                                    <div className='card-body'>
                                        <form onSubmit={handleSubmitEdtIntl}>
                                            {currentStepEdt === 2 && (
                                                <Grow in={currentStepEdt === 2}>
                                                    <div>
                                                        <h5><BsIcons.BsPeople className='mb-1'/> 
                                                            &nbsp; User Information
                                                        </h5>
                                                        <Divider/>
                                                        <br/>
                                                        <TextField id="lnameEdt" 
                                                        label="Last Name" variant="outlined" required
                                                        size="small" fullWidth InputLabelProps={{ style: labelStyle }}
                                                        type="text" name="field1" value={lnameEdt} 
                                                        onChange={(e)=>{setLnameEdt(e.target.value)}} />

                                                        <TextField className="mt-3" id="fnameEdt" 
                                                        label="First Name" variant="outlined" required
                                                        size="small" fullWidth InputLabelProps={{ style: labelStyle }}
                                                        type="text" name="field2" value={fnameEdt} 
                                                        onChange={(e)=>{setFnameEdt(e.target.value)}} /> 

                                                        <TextField className="mt-3" id="mnameEdt" 
                                                        label="Middle Name" variant="outlined" required
                                                        size="small" fullWidth InputLabelProps={{ style: labelStyle }}
                                                        type="text" name="field3" value={mnameEdt} 
                                                        onChange={(e)=>{setMnameEdt(e.target.value)}} />    

                                                        <TextField className="mt-3" id="emailEdt" 
                                                        sx={{
                                                            '& .MuiOutlinedInput-root': {
                                                              '& fieldset': {
                                                                borderColor: colorAddEdt,
                                                              },
                                                            },
                                                        }}
                                                        label="Email Address" variant="outlined" required
                                                        size="small" fullWidth InputLabelProps={{ style: labelStyle }}
                                                        type="text" name="field4" value={emailEdt} 
                                                        onChange={(e)=>{setEmailEdt(e.target.value)}}
                                                        onChangeCapture={(e)=>{handleEmailVldtyEdt(e, e.target.value)}} />  
                                                        <p className='text-xs float-right' style={{color:colorAddEdt}}>
                                                            {emailLblEdt}
                                                        </p>

                                                        <TextField className="mb-2" id="empnoEdt" 
                                                        label="Employee No." variant="outlined" required
                                                        size="small" fullWidth InputLabelProps={{ style: labelStyle }}
                                                        type="text" name="field4" value={empnoEdt} 
                                                        onChange={(e)=>{setEmpnoEdt(e.target.value)}} />  
                                                    </div>
                                                </Grow>
                                            )}

                                            {currentStepEdt === 2 && (
                                            <div className='mt-4'>
                                                <Button variant="outlined" onClick={() => setCurrentStepEdt(currentStepEdt - 1)}>
                                                    Cancel
                                                </Button> &nbsp;
                                                <Button variant="contained" className='w-25' 
                                                type='submit'>
                                                    Next
                                                </Button>
                                            </div>
                                            )}
                                        </form>

                                        <form onSubmit={handleSubmitEdt}>
                                            {currentStepEdt === 3 && (
                                                <Grow in={currentStepEdt === 3}>
                                                    <div>
                                                        <h5><BsIcons.BsGear className='mb-1'/> Account Information</h5>
                                                        <Divider/>
                                                        <br/>
                                                        <FormControl size="small" fullWidth>
                                                            <InputLabel id="demo-simple-select-label" style={selectLabelStyle}>Access Level *</InputLabel>
                                                            <Select required
                                                                labelId=""
                                                                id=""
                                                                value={accessLevelEdt}
                                                                label="Access Level"
                                                                onChange={handleChangeAccessLevelEdt}
                                                            >
                                                                <MenuItem value="Main Office">Main Office Access</MenuItem>
                                                                <MenuItem value="Region">Region Office Access</MenuItem>
                                                                <MenuItem value="District">District Office Access</MenuItem>
                                                            </Select>
                                                        </FormControl>

                                                        <FormControl size="small" className="mt-3" fullWidth
                                                        disabled={accessLevelEdt === 'Main Office'}>
                                                            <InputLabel id="demo-simple-select-label" style={selectLabelStyle}>
                                                                {accessLevelEdt === 'Main Office'?'Office *':'Region *'}
                                                            </InputLabel>
                                                            <Select required
                                                                labelId=""
                                                                id=""
                                                                value={regionEdt}
                                                                label={accessLevelEdt === 'Main Office'?'Office':'Region'}
                                                                onChange={handleChangeRegionEdt}
                                                            >
                                                                {regData.map((dt)=>{
                                                                    if(dt.id === '20' && accessLevelEdt != 'Main Office'){
                                                                        return <MenuItem key={dt.id} value={dt.id} hidden>
                                                                            {dt.ro_desc}
                                                                        </MenuItem>
                                                                    }
                                                                    else{
                                                                        return <MenuItem key={dt.id} value={dt.id}>{dt.ro_desc}</MenuItem>
                                                                    }
                                                                })}
                                                            </Select>
                                                        </FormControl>

                                                        <FormControl size="small" className="mt-3" fullWidth>
                                                            <InputLabel id="demo-simple-select-label" style={selectLabelStyle}>
                                                                {accessLevelEdt === 'Main Office'?'Department *':'District *'}
                                                            </InputLabel>
                                                            <Select required
                                                                labelId=""
                                                                id=""
                                                                value={districtEdt}
                                                                label={accessLevelEdt === 'Main Office'?'Department':'District'}
                                                                onChange={handleChangeDistrictEdt}
                                                                disabled={accessLevelEdt === 'Region'}
                                                            >
                                                                <MenuItem value='not applicable' hidden>
                                                                    Not Applicable
                                                                </MenuItem>
                                                                <MenuItem value='ACCOUNTING' hidden={accessLevelEdt != 'Main Office'}>
                                                                    ACCOUNTING
                                                                </MenuItem>
                                                                <MenuItem value='COSDD' hidden={accessLevelEdt != 'Main Office'}>
                                                                    COSDD
                                                                </MenuItem>

                                                                {distData.map((dt)=>{
                                                                    if(regionEdt === dt.ro_id){
                                                                        return <MenuItem key={dt.id} value={dt.id}>{dt.do_desc}</MenuItem>
                                                                    }
                                                                    else{
                                                                        // Do nothing
                                                                    }
                                                                })}
                                                            </Select>
                                                        </FormControl>

                                                        <FormControl size="small" className="mt-3" fullWidth>
                                                            <InputLabel id="demo-simple-select-label" style={selectLabelStyle}>Status</InputLabel>
                                                            <Select required
                                                                labelId=""
                                                                id=""
                                                                value={statusEdt}
                                                                label="Status"
                                                                onChange={handleChangeStatusEdt}
                                                            >
                                                                <MenuItem value='Active'>Active</MenuItem>
                                                                <MenuItem value='Inactive'>Inactive</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                </Grow>
                                            )}

                                            {currentStepEdt === 3 && (
                                            <div className='mt-4'>
                                                <Button variant="outlined" onClick={() => setCurrentStepEdt(currentStepEdt - 1)}>
                                                    Previous
                                                </Button> &nbsp;
                                                <Button variant="contained" className='w-25' 
                                                type="submit"
                                                disabled={sbmtAddEdt}>
                                                    Submit
                                                </Button>
                                            </div>
                                            )}
                                        </form>
                                    </div>
                                </div>
                            </Grow>
                        )}
                    </Box>
                </Modal>

            </section>
        </div>
    </div>
)}

export default SystemUsers
