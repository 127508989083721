import { useState } from 'react';
import { HashRouter, Route, Routes} from "react-router-dom";
import Login from "./Login";
import NameSearch from "./pages/NameSearch";
import ExcelSearch from "./pages/ExcelSearch";
import AuditTrail from "./pages/AuditTrail";
import SystemUsers from "./pages/SystemUsers";
import AccountSetting from "./pages/AccountSetting";
import Protected from "./Protected";

const App = () => {
  const url_origin = "https://remittancelist.nha.gov.ph";
  const url_str = "https://api.nha.gov.ph/alp";
  // const url_origin = "http://localhost:3000";
  // const url_str = "http://localhost:4000/alp";
  return (
    <HashRouter>
      <Routes>
          <Route path="/" element={<Login url_str={url_str} url_origin={url_origin} />}/>
          <Route>
            <Route exact path="/name_srch" element={
              <Protected url_str={url_str}>
                <NameSearch url_str={url_str} url_origin={url_origin} />
              </Protected>}
            />
            <Route path="/excl_srch" element={
              <Protected url_str={url_str}>
                <ExcelSearch url_str={url_str} url_origin={url_origin} />
              </Protected>}
            />
            <Route path="/audt_trl" element={
              <Protected url_str={url_str}>
                <AuditTrail url_str={url_str} url_origin={url_origin} />
              </Protected>}
            />
            <Route path="/system_urs" element={
              <Protected url_str={url_str}>
                <SystemUsers url_str={url_str} url_origin={url_origin} />
              </Protected>}
            />
            <Route path="/accnt_sttng" element={
              <Protected url_str={url_str}>
                <AccountSetting url_str={url_str} url_origin={url_origin} />
              </Protected>}
            />
          </Route>
      </Routes>
    </HashRouter>
  );
}

export default App;
