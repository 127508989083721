import React from "react";
import { Navigate } from "react-router-dom";
import handleDecrypt from './functions/fx_dcrpt';

const Protected = ({ url_str, children }) => {
const [isLoggedIn, setisLoggedIn] = React.useState();

  fetch(`${url_str}/checksession`,{credentials:"include"})
  .then((response) => response.json())
  .then((responseJson) => {
    const res = JSON.parse(handleDecrypt(responseJson));
    if (res.response === "notLoggedIn") {
      return setisLoggedIn(false);
    } 
    else {
      return setisLoggedIn(true),
      localStorage.setItem("access",res.access), 
      localStorage.setItem("district",res.district);
    }

  })
  .catch((error) => {
    console.log(error)
    console.log("Session not Established...");
    return setisLoggedIn(false);
  });
 
  if (isLoggedIn === true) {
    return children;
  }
  else if (isLoggedIn === false) {
    return <Navigate to='/' replace />;
  }

};

export default Protected;