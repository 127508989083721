import React, { useState, useEffect } from "react";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import * as BsIcons from "react-icons/bs";
// mui
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import handleDecrypt from '../functions/fx_dcrpt';
import MuiViewSubhist from "../modals/search/MuiViewSubhist";

const NameSearch = ({ url_str, url_origin }) => {

const labelStyle = {
    fontWeight: 'normal',
};

// MODAL VIEW INFO
const [openViewModal, setOpen] = useState(false);
const handleOpen = () => setOpen(true);
const handleClose = () => setOpen(false);
// MODAL VIEW INFO

// FORMAT NUMBER WITH COMMA
const NumFormat = new Intl.NumberFormat();
// FORMAT NUMBER WITH COMMA

// HEADERS
let headers = new Headers();
headers.append("Content-Type", "application/json");
headers.append("Accept", "application/json");
headers.append("Origin", `${url_origin}`);
// HEADERS

const [load1, setLoad1] = useState(false)
const [load2, setLoad2] = useState(false)

const [fnamevar, setFname] = useState("");
const [lnamevar, setLname] = useState("");
const [mnamevar, setMname] = useState("");
const [bcsvar, setBcs20] = useState([]);
const [lisvar, setLisvar] = useState([]);

const execSearch = async(e) => {
    e.preventDefault();
    setBcs20([])
    setLisvar([])
    setLoad1(true);
    setLoad2(true);
    srchBCS20()
    srchLISBCS20()
}

// SEARCH THRU BCS20
const srchBCS20 = async() => {
    const response = await fetch(`${url_str}/searchBCS20`, { 
        mode: "cors",
        method: "POST",
        headers: headers,
        credentials: "include",
        body: JSON.stringify({
            firstname: fnamevar,
            lastname: lnamevar,
            middlename: mnamevar,
        }),
    });
    const encrptdata = await response.json();
    const dataValue = handleDecrypt(encrptdata);
    const data = await JSON.parse(dataValue);
    setBcs20(data.rows)
    setLoad1(false);
}
// SEARCH THRU BCS20

// SEARCH THRU LISBCS20
const srchLISBCS20 = async() => {
    const response = await fetch(`${url_str}/searchLISBCS20`, { 
        mode: "cors",
        method: "POST",
        headers: headers,
        credentials: "include",
        body: JSON.stringify({
            firstname: fnamevar,
            lastname: lnamevar,
            middlename: mnamevar,
        }),
    });
    const encrptdata = await response.json();
    const dataValue = handleDecrypt(encrptdata);
    const data = await JSON.parse(dataValue);
    // let pp = data.rows.filter((ele, ind) => ind === data.rows.findIndex(
    //     elem => elem.COM_CODE === ele.COM_CODE
    // ))
    setLisvar(data.rows)
    setLoad2(false);
}
// SEARCH THRU LISBCS20

// SEARCH THRU SUBHIST
const [subhist, setSubhist] = useState([]);
const srchSubHist = async(val) => {
    const response = await fetch(`${url_str}/searchSubHist`, { 
        mode: "cors",
        method: "POST",
        headers: headers,
        credentials: "include",
        body: JSON.stringify({
            com_code: val,
        }),
    });
    const encrptdata = await response.json();
    const dataValue = handleDecrypt(encrptdata);
    const data = await JSON.parse(dataValue);

    let yr,mnth,dy,bdate;
    if(data.rows.length>0){
        let newItems = [...data.rows];
        for(let indx=0; indx<newItems.length; indx++){
            yr = newItems[indx]['BDATE'].substring(6,10);
            mnth = newItems[indx]['BDATE'].substring(3,5);
            dy = newItems[indx]['BDATE'].substring(0,2);
            bdate = yr+'-'+mnth+'-'+dy;
            newItems[indx] = { ...newItems[indx], BDATE: bdate };
        }
        setSubhist(newItems)
    }
    else{
        // Do nothing
        setSubhist(data.rows)
    }
}
// SEARCH THRU SUBHIST

return (
    <div>
    <Sidebar/>
    <Header url_str={url_str} url_origin={url_origin} />
    <div className="content-wrapper pl-2 pr-2 pb-5">
        <section className="content-header">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-6">
                    <h1>Name Search</h1>
                    </div>
                </div>
            </div>
        </section>
        <section className="content">
            <div className="card ml-2 mr-2">
                <div className="card-body">
                    <form onSubmit={execSearch}>
                        <div className='row'>
                            <div className='col-sm-10'>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <TextField label="First Name" 
                                        value={fnamevar}
                                        onChange={(e)=>{setFname(e.target.value)}}
                                        variant="outlined" 
                                        placeholder="Type here" 
                                        size="small" required
                                        InputLabelProps={{style: labelStyle}} fullWidth />
                                    </div>
                                    <div className='col-sm-4'>
                                        <TextField label="Middle Name" 
                                        value={mnamevar}
                                        onChange={(e)=>{setMname(e.target.value)}}
                                        variant="outlined" 
                                        placeholder="Type here" 
                                        size="small"
                                        InputLabelProps={{style: labelStyle}} fullWidth />
                                    </div>
                                    <div className='col-sm-4'>
                                        <TextField label="Last Name" 
                                        value={lnamevar}
                                        onChange={(e)=>{setLname(e.target.value)}}
                                        variant="outlined" 
                                        placeholder="Type here" 
                                        size="small" required
                                        InputLabelProps={{style: labelStyle}} fullWidth />
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-2'>
                                <Button className='h-100' variant="contained" disableElevation fullWidth
                                type="submit">
                                    <BsIcons.BsSearch className='mb-1'/>&nbsp;Search 
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div className='card m-2 mt-3'>
                <div className='card-header'>
                    <h3 className='card-title font-weight-bold'><BsIcons.BsDatabase className='mb-1'/> BCS-BCS20</h3>
                </div>

                <div className='card-body p-0 table-responsive'>
                    <table className='table table-head-fixed table-hover text-nowrap'>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Region</th>
                                <th>Location</th>
                                <th>Project Name</th>
                                <th>BIN</th>
                                <th>Common Code</th>
                                <th>Last Name</th>
                                <th>First Name</th>
                                <th>Middle Name</th>
                                <th>Birth Date</th>
                                <th>Account Balance</th>
                                <th>Last Payment</th>
                            </tr>
                        </thead>
                        <tbody id={load1?'loading-table':''}>
                            {bcsvar.length>0
                            ?bcsvar.map((bcs, index)=>
                                <tr key={index} 
                                onClick={(e)=>{srchSubHist(bcs.com_code); handleOpen();}}
                                style={{cursor:'pointer'}}>
                                    <td>{index + 1}</td>
                                    <td>{bcs.ro_region}</td>
                                    <td>{bcs.loc_desc}</td>
                                    <td>{bcs.pj_name}</td>
                                    <td>{bcs.bin}</td>
                                    <td>{bcs.com_code}</td>
                                    <td>{bcs.lname}</td>
                                    <td>{bcs.fname}</td>
                                    <td>{bcs.mname}</td>
                                    <td>
                                        {bcs.bdate!=null&&bcs.bdate!=''&&bcs.bdate != undefined
                                        ?new Date(bcs.bdate).toDateString()
                                        :<i>Not Available</i>}   
                                    </td>
                                    <td className='text-right'>{NumFormat.format(bcs.baltotal)}</td>
                                    <td>
                                        {bcs.monpdto!=null&&bcs.monpdto!=''&&bcs.monpdto != undefined
                                        ?new Date(bcs.monpdto).toDateString()
                                        :<i>Not Available</i>} 
                                    </td>
                                </tr>
                            )
                            :<tr>
                                <td colSpan={14} className='text-center'>&nbsp;<i hidden={load1}> No Available Data </i>&nbsp;</td>
                            </tr>}
                        </tbody>
                    </table>
                </div>
            </div>

            <div className='card m-2 mt-3'>
                <div className='card-header'>
                    <h3 className='card-title font-weight-bold'><BsIcons.BsDatabase className='mb-1'/> LIS-BCS20</h3>
                </div>

                <div className='card-body p-0 table-responsive'>
                    <table className='table table-head-fixed table-hover text-nowrap'>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Region</th>
                                <th>Location</th>
                                <th>Project Name</th>
                                <th>Common Code</th>
                                <th>Last Name</th>
                                <th>First Name</th>
                                <th>Middle Name</th>
                                <th>Birth Date</th>
                                <th>Address</th>
                            </tr>
                        </thead>
                        <tbody id={load2?'loading-table':''}>
                            {lisvar.length>0
                            ?lisvar.map((lis, index)=>
                                <tr key={index} 
                                onClick={(e)=>{srchSubHist(lis.com_code); handleOpen();}} 
                                style={{cursor:'pointer'}}>
                                    <td>{index + 1}</td>
                                    <td>{lis.ro_region}</td>
                                    <td>{lis.loc_desc}</td>
                                    <td>{lis.pj_name}</td>
                                    <td>{lis.com_code}</td>
                                    <td>{lis.lname}</td>
                                    <td>{lis.fname}</td>
                                    <td>{lis.mname}</td>
                                    <td>
                                        {lis.bdate!=null&&lis.bdate!=''&&lis.bdate != undefined
                                        ?new Date(lis.bdate).toDateString()
                                        :<i>Not Available</i>} 
                                    </td>
                                    <td className='text-wrap'>{lis.address}</td>
                                </tr>
                            )
                            :<tr>
                                <td colSpan={12} className='text-center'>&nbsp;<i hidden={load2}> No Available Data </i>&nbsp;</td>
                            </tr>}
                        </tbody>
                    </table>
                </div>
            </div>

            <MuiViewSubhist
            openViewModal = {openViewModal}
            handleClose = {handleClose}
            subhist = {subhist}
            />

        </section>
    </div>
    </div>
  )
}

export default NameSearch
