import { useState, useEffect } from "react";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import Pagination from "../table/Pagination";
import * as BsIcons from "react-icons/bs";
import * as XLSX from "xlsx";
import * as FaIcons  from 'react-icons/fa';
// mui
import Button from '@mui/material/Button';
import handleDecrypt from '../functions/fx_dcrpt';
import {exclReportBCS} from "../report/ExclReport";
import MuiViewSubhist from "../modals/search/MuiViewSubhist";

const ExcelSearch = ({ url_str, url_origin }) => {

// MODAL VIEW INFO
const [openViewModal, setOpen] = useState(false);
const handleOpen = () => setOpen(true);
const handleClose = () => setOpen(false);
// MODAL VIEW INFO

// FORMAT NUMBER WITH COMMA
const NumFormat = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
// FORMAT NUMBER WITH COMMA

// HEADERS
let headers = new Headers();
headers.append("Content-Type", "application/json");
headers.append("Accept", "application/json");
headers.append("Origin", `${url_origin}`);
// HEADERS

const [reloadtable, setReloadtable] = useState("");
const [filename, setFilename] = useState("");
const [load1, setLoad1] = useState(false)
const [load2, setLoad2] = useState(false)
const [load3, setLoad3] = useState(false)
const [final, setFinal] = useState("")

// SEARCH OBJECT
const [excelArry, setExcelArry] = useState();
// SEARCH OBJECT

// EXCEL ITEMS
const [excelRaw, setExcelRaw] = useState([]);
const [excelItems, setExcelItems] = useState([]);
// EXCEL ITEMS

// EXCEL PAGINATION
const [exclTotPgs, setExclTotPgs] = useState(0);
const exclNumPerPage = 5;
const [exclTotRowCnt, setExclTotRowCnt] = useState('');
const [exclRowCnt, setExclRowCnt] = useState('');
const [exclFrstIndx, setExclFrstIndx]= useState(1)

const setExcelSlice = async(num) => {
    const last = (num * exclNumPerPage);
    const first = last - exclNumPerPage;
    let currentDataXlsx = excelRaw.slice(first, last);
    setExcelItems(currentDataXlsx);
    setExclRowCnt(currentDataXlsx.length)
    setExclFrstIndx(first)
}
// EXCEL PAGINATION

// BCS ITEMS
const [bcsRaw, setBcsRaw] = useState([]);
const [bcsItems, setBcsItems] = useState([]);
// BCS ITEMS

// BCS PAGINATION
const [bcsTotPgs, setBcsTotPgs] = useState(0);
const bcsNumPerPage = 5;
const [bcsTotRowCnt, setBcsTotRowCnt] = useState('');
const [bcsRowCnt, setBcsRowCnt] = useState('');
const [bcsFrstIndx, setBcsFrstIndx]= useState(1)

const setBcsSlice = async(num) => {
    const last = (num * bcsNumPerPage);
    const first = last - bcsNumPerPage;
    let currentData = bcsRaw.slice(first, last);
    setBcsItems(currentData);
    setBcsRowCnt(currentData.length)
    setBcsFrstIndx(first)
}
// BCS PAGINATION

// LIS ITEMS
const [lisRaw, setLisRaw] = useState([]);
const [lisItems, setLisItems] = useState([]);
// LIS ITEMS

// LIS PAGINATION
const [lisTotPgs, setLisTotPgs] = useState(0);
const lisNumPerPage = 5;
const [lisTotRowCnt, setLisTotRowCnt] = useState('');
const [lisRowCnt, setLisRowCnt] = useState('');
const [lisFrstIndx, setLisFrstIndx]= useState(1)

const setLisSlice = async(num) => {
    const last = (num * lisNumPerPage);
    const first = last - lisNumPerPage;
    let currentData = lisRaw.slice(first, last);
    setLisItems(currentData);
    setLisRowCnt(currentData.length)
    setLisFrstIndx(first)
}
// LIS PAGINATION

// EMPTY PAGINATION VALUES
const emptyPgVal = async() => {
    setExclTotPgs(0)
    setExclTotRowCnt('')
    setExclRowCnt('')
    setExclFrstIndx(1)
    
    setBcsTotPgs(0)
    setBcsTotRowCnt('')
    setBcsRowCnt('')
    setBcsFrstIndx(1)
    
    setLisTotPgs(0)
    setLisTotRowCnt('')
    setLisRowCnt('')
    setLisFrstIndx(1)
}
// EMPTY PAGINATION VALUES

// EXTRACT DATA FROM EXCEL
const readExcel = (file) => {
    setLoad1(true);
    const excelArry = [];
    if(file != undefined){
        const promise = new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);
            fileReader.onload = (e) => {
                const bufferArray = e.target.result;
                const wb = XLSX.read(bufferArray);
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                const data = XLSX.utils.sheet_to_json(ws);
                resolve(data);
                setReloadtable(file);
                setFilename(file.name);
            };
    
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    
        promise.then((dataXlsx) => {
            emptyPgVal()
            setBcsItems([]);
            setLisItems([]);
            setExcelRaw(dataXlsx);
            setExclTotRowCnt(dataXlsx.length);
            let totalpages = Math.ceil(dataXlsx.length / exclNumPerPage);
            setExclTotPgs(totalpages)
            const last = (1 * exclNumPerPage);
            const first = last - exclNumPerPage;
            let currentDataXlsx = dataXlsx.slice(first, last);
            setExclRowCnt(currentDataXlsx.length)
            setExcelItems(currentDataXlsx);
            setExclFrstIndx(first)
            
            for(const element of dataXlsx){
                excelArry.push([element['FIRST NAME'], element['LAST NAME'], element['MIDDLE NAME']])
            }
            setLoad1(false);
            (window.setTimeout(() => {
                let arraytxt = JSON.stringify(excelArry)
                let txt1 = arraytxt.replace(/\[/g, '(');
                let txt2 = txt1.replace(/\]/g, ')');
                let txt3 = txt2.replace(/\"/g, `'`);
                setExcelArry(txt3);
            }, 300))
        })
    }
    else{
        setLoad1(false);
        (window.setTimeout(() => {
            // setExcelItems([]);
            setReloadtable("");
        }, 300))
    }
};
// EXTRACT DATA FROM EXCEL

// EXECUTE SEARCH
const execSearch = async(e) => {
    setBcsItems([]);
    setLisItems([]);
    setLoad2(true);
    setLoad3(true);
    // srchBCS20();
    srchLISBCS20();
    srchBCS1()
}
// EXECUTE SEARCH

// SEARCH THRU BCS20
const srchBCS20 = async() => {
    const response = await fetch(`${url_str}/searchBCS20batch`, { 
        mode: "cors",
        method: "POST",
        headers: headers,
        credentials: "include",
        body: JSON.stringify({
            array: excelArry,
        }),
    });
    const encrptdata = await response.json();
    const dataValue = handleDecrypt(encrptdata);
    const data = await JSON.parse(dataValue);
    setFinal(final + 1)
    setBcsRaw(data.rows)
    setBcsTotRowCnt(data.rows.length);
    let totalpages = Math.ceil(data.rows.length / bcsNumPerPage);
    setBcsTotPgs(totalpages)
    const last = (1 * bcsNumPerPage);
    const first = last - bcsNumPerPage;
    let currentDataXlsx = data.rows.slice(first, last);
    setBcsRowCnt(currentDataXlsx.length)
    setBcsItems(currentDataXlsx);
    setBcsFrstIndx(first)
    setLoad2(false);
}
// SEARCH THRU BCS20

// SEARCH THRU LISBCS20
const srchLISBCS20 = async() => {
    const response = await fetch(`${url_str}/searchLISBCS20batch`, { 
        mode: "cors",
        method: "POST",
        headers: headers,
        credentials: "include",
        body: JSON.stringify({
            array: excelArry,
        }),
    });
    const encrptdata = await response.json();
    const dataValue = handleDecrypt(encrptdata);
    const data = await JSON.parse(dataValue);

    // COMPARE TWO ARRAYS TO EXTRACT UNMATCHED ROWS
    const unmatched = await cmprArrys(excelRaw, data.rows);
    // COMPARE TWO ARRAYS TO EXTRACT UNMATCHED ROWS

    // STRINGIFY COMPARED ROWS
    const strngArry = await strgfyArry(unmatched);
    // STRINGIFY COMPARED ROWS

    // GET FROM EXCEL DBASE THRU API
    const bcsUnmtchd = await bcsUnmtchdGet(strngArry);
    // GET FROM EXCEL DBASE THRU API
    
    // COMBINE TWO OBJECTS
    const cmbndObjset = await cmbnObj(bcsUnmtchd, data.rows);
    // COMBINE TWO OBJECTS

    setFinal(final + 1)
    setLisRaw(cmbndObjset)
    setLisTotRowCnt(cmbndObjset.length);
    let totalpages = Math.ceil(cmbndObjset.length / lisNumPerPage);
    setLisTotPgs(totalpages)
    const last = (1 * lisNumPerPage);
    const first = last - lisNumPerPage;
    let currentDataXlsx = cmbndObjset.slice(first, last);
    setLisRowCnt(currentDataXlsx.length)
    setLisItems(currentDataXlsx);
    setLisFrstIndx(first)
    setLoad3(false);
}
// SEARCH THRU LISBCS20

// SEARCH THRU SUBHIST
const [subhist, setSubhist] = useState([]);
const srchSubHist = async(val) => {
    const response = await fetch(`${url_str}/searchSubHist`, { 
        mode: "cors",
        method: "POST",
        headers: headers,
        credentials: "include",
        body: JSON.stringify({
            com_code: val,
        }),
    });
    const encrptdata = await response.json();
    const dataValue = handleDecrypt(encrptdata);
    const data = await JSON.parse(dataValue);
    
    let yr,mnth,dy,bdate;
    if(data.rows.length>0){
        let newItems = [...data.rows];
        for(let indx=0; indx<newItems.length; indx++){
            yr = newItems[indx]['BDATE'].substring(6,10);
            mnth = newItems[indx]['BDATE'].substring(3,5);
            dy = newItems[indx]['BDATE'].substring(0,2);
            bdate = yr+'-'+mnth+'-'+dy;
            newItems[indx] = { ...newItems[indx], BDATE: bdate };
        }
        setSubhist(newItems)
    }
    else{
        setSubhist(data.rows)
    }
}
// SEARCH THRU SUBHIST

// COMPARE TWO ARRAYS TO EXTRACT UNMATCHED ROWS
const cmprArrys = async(obj1, obj2) => {
    let unmatched = [];
    for (const element of obj1) {
        const bcs = obj2.filter((obj) => 
            element['LAST NAME'] === obj.lname &&
            element['MIDDLE NAME'] === obj.mname && 
            element['FIRST NAME'] === obj.fname
        )
        if(bcs.length < 1){
            unmatched.push(element)
        }
        else{
            // Do nothing
        }
    }
    return unmatched;
}
// COMPARE TWO ARRAYS TO EXTRACT UNMATCHED ROWS

// STRINGIFY COMPARED ROWS
const strgfyArry = async(unmatched) => {
    const Arry = [];
    for(const element of unmatched){
        Arry.push(`${[element['SERIAL NR']]}`)
    }
    return Arry;
}
// STRINGIFY COMPARED ROWS

// GET FROM EXCEL DBASE THRU API
const bcsUnmtchdGet = async(srlNum) => {
    const response = await fetch(`${url_str}/afpDbase`, { 
        mode: "cors",
        method: "POST",
        headers: headers,
        credentials: "include",
        body: JSON.stringify({
            array: srlNum,
        }),
    });
    const encrptdata = await response.json();
    const dataValue = handleDecrypt(encrptdata);
    const data = await JSON.parse(dataValue);

    return (data)
}
// GET FROM EXCEL DBASE THRU API

// COMBINE TWO OBJECTS
const cmbnObj = async(bcsUnmtchd, data) => {
    let resUnmtchArry = [];
    for(const element of bcsUnmtchd){
        if(element['BIN']===undefined){
            // Do nothing
        }
        else{
            resUnmtchArry.push(
                {
                    bin: `${element['BIN']}`,
                    lname: element['LAST NAME'],
                    mname: element['MIDDLE NAME'],
                    fname: element['FIRST NAME'],
                    bdate: '',
                    com_code: '',
                    serial: `${element['SERIAL NO.']}`,
                }
            )
        }
    }

    const fnlDtRows = [
        ...data,
        ...resUnmtchArry
    ]
    return fnlDtRows
}
// COMBINE TWO OBJECTS

// SEARCH THRU BCS20 STEP 1
const srchBCS1 = async() => {
    const response = await fetch(`${url_str}/srchBCS1`, { 
        mode: "cors",
        method: "POST",
        headers: headers,
        credentials: "include",
        body: JSON.stringify({
            array: excelArry,
        }),
    });
    const encrptdata = await response.json();
    const dataValue = handleDecrypt(encrptdata);
    const data = await JSON.parse(dataValue);

    // COMPARE TWO ARRAYS TO EXTRACT UNMATCHED ROWS
    const unmatched = await cmprArrys(excelRaw, data.rows);
    // COMPARE TWO ARRAYS TO EXTRACT UNMATCHED ROWS

    // STRINGIFY COMPARED ROWS
    const strngArry = await strgfyArry(unmatched);
    // STRINGIFY COMPARED ROWS

    // GET FROM EXCEL DBASE THRU API
    const bcsUnmtchd = await bcsUnmtchdGet(strngArry);
    // GET FROM EXCEL DBASE THRU API
    
    // COMBINE TWO OBJECTS
    const cmbndObjset = await cmbnObj(bcsUnmtchd, data.rows);
    // COMBINE TWO OBJECTS

    const bcsArry = [];
    for(const element of cmbndObjset){
        bcsArry.push([element['bin']])
    }
    (window.setTimeout(() => {
        let arraytxt = JSON.stringify(bcsArry)
        let txt1 = arraytxt.replace(/\[/g, '(');
        let txt2 = txt1.replace(/\]/g, ')');
        let txt3 = txt2.replace(/\"/g, `'`);
        srchBCS2(cmbndObjset, txt3);
    }, 300))
}
// SEARCH THRU BCS20 STEP 1

// SEARCH THRU BCS20 STEP 2
const srchBCS2 = async(bcs20, arry) => {
    const response = await fetch(`${url_str}/srchBCS2`, { 
        mode: "cors",
        method: "POST",
        headers: headers,
        credentials: "include",
        body: JSON.stringify({
            array: arry,
        }),
    });
    const encrptdata = await response.json();
    const dataValue = handleDecrypt(encrptdata);
    const data = await JSON.parse(dataValue);

    const result = bcs20.map(dtbcs20 => {
        const item2 = data.rows.find(dtbcs30 => dtbcs20.bin === dtbcs30.bin);
        return {
          ...dtbcs20,
          ...item2
        };
    });
    let sorted = result.toSorted((a, b) => {
        const fullNameA = `${a['lname']}`;
        const fullNameB = `${b['lname']}`;
        return fullNameA.localeCompare(fullNameB);
    });
    
    setFinal(final + 1)
    setBcsRaw(sorted)
    setBcsTotRowCnt(sorted.length);
    let totalpages = Math.ceil(sorted.length / bcsNumPerPage);
    setBcsTotPgs(totalpages)
    const last = (1 * bcsNumPerPage);
    const first = last - bcsNumPerPage;
    let currentDataXlsx = sorted.slice(first, last);
    setBcsRowCnt(currentDataXlsx.length)
    setBcsItems(currentDataXlsx);
    setBcsFrstIndx(first)
    setLoad2(false);
}
// SEARCH THRU BCS20 STEP 2

// JOIN TWO OBJECTS
const joinedObj = async() => {
    document.getElementById('icnSubmitRotate').hidden = false;
    document.getElementById('icnSubmit').hidden = true;
    const filterobject = [];
    for(const item of excelRaw){
        const bcs = bcsRaw.filter((obj) => 
            item['LAST NAME'] === obj.lname &&
            item['FIRST NAME'] === obj.fname && 
            item['MIDDLE NAME'] === obj.mname
        );
        if(bcs.length > 0) {
            let PJNAME = (bcs[0].pj_name)
            let LOCDESC = (bcs[0].loc_desc)
            let ROID = (bcs[0].ro_id)
            let ROREGION = (bcs[0].ro_region)
            filterobject.push( {...item, ROID, ROREGION, PJNAME, LOCDESC, bcs} )
        }
        else{
            const bcs = bcsRaw.filter((obj) => 
                `${item['SERIAL NR']}` === obj.serial
            );
            if(bcs.length > 0) {
                let PJNAME = (bcs[0].pj_name)
                let LOCDESC = (bcs[0].loc_desc)
                let ROID = (bcs[0].ro_id)
                let ROREGION = (bcs[0].ro_region)
                filterobject.push( {...item, ROID, ROREGION, PJNAME, LOCDESC, bcs} )
            }
            else{
                // continue;
            }
        }
    };
    let sorted = filterobject.toSorted((a, b) => {
        const sortval1 = `${a['ROID']} ${a['PJNAME']} ${a['LOCDESC']} ${a['LAST NAME']}`;
        const sortval2 = `${b['ROID']} ${b['PJNAME']} ${b['LOCDESC']} ${b['LAST NAME']}`;
        return sortval1.localeCompare(sortval2);
    });
    
    const uniqueValues = (arr, key) => {
        return [...new Set(arr.map(item => item[key]))];
    };
    
    // const test = []
    // for(const item of bcsRaw){
    //     test.push({
    //         lname: item['lname'],
    //         mname: item['mname'],
    //         fname: item['fname'],
    //         serial: item['serial'],
    //     })
    // }

    // const uniqueArr = test.filter((value, index, self) => {
    //     return self.findIndex((item) => {
    //         return item.lname === value.lname && item.mname === value.mname && item.fname === value.fname;
    //     }) === index;
    // });
    // console.log(uniqueArr)
    // console.log(sorted)
      
    const regions = uniqueValues(sorted, 'ROREGION');
    printReport( sorted, regions )
}

const printReport = async(filterobject, regions) => {
    let res = await exclReportBCS(filterobject, regions)
    if(res === 'success') {
        window.setTimeout(() => {
            document.getElementById('icnSubmitRotate').hidden = true;
            document.getElementById('icnSubmit').hidden = false;
        }, 300)
    }
}
// JOIN TWO OBJECTS

useEffect(() => {
    setReloadtable("")
}, [final])
 
return (
    <div>
    <Header url_str={url_str} url_origin={url_origin} />
    <Sidebar/>

    <div className="content-wrapper pl-2 pr-2 pb-5">
        <section className="content-header">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-6">
                        <h1>Excel Search</h1>
                    </div>
                    <div className="col text-right">
                        <Button variant="contained"
                        className="col-3"
                        onClick={joinedObj}
                        disabled={bcsItems.length<1}
                        disableElevation>
                            <span id='icnSubmit'>Print Results</span>
                            <span id='icnSubmitRotate' hidden>
                            <FaIcons.FaSpinner className='rotating'/>
                            </span>
                        </Button>
                    </div>
                </div>
            </div>
        </section>
        <section className="content">
            <div className="card ml-2 mr-2">
                <div className="card-body">
                    <div className='row'>
                        <div className='col-sm-10'>
                        <div className="form-group mb-0">
                            <div className="custom-file">
                                <input type="file" className="custom-file-input" id="customFile" 
                                onChange={(e) => {
                                const file = e.target.files[0];
                                readExcel(file);
                                }} />
                                <label className="custom-file-label" htmlFor="customFile">{filename?filename:'Choose file'}</label>
                            </div>
                        </div>
                        </div>
                        <div className='col-sm-2'>
                        <Button className='h-100' variant="contained" disabled={excelItems.length<1}
                        onClick={execSearch} 
                        disableElevation fullWidth>
                            <BsIcons.BsSearch className='mb-1'/>&nbsp;Search 
                        </Button>
                        </div>
                    </div>
                </div>
            </div>

            <div className='card m-2'>
                <div className='card-header'>
                    <h3 className='card-title font-weight-bold'><BsIcons.BsDatabase className='mb-1'/> EXCEL DATA</h3>
                </div>

                <div className='card-body p-0 table-responsive'>
                    <table className='table table-head-fixed table-hover text-nowrap'>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Last Name</th>
                                <th>First Name</th>
                                <th>Middle Name</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody id={load1?'loading-table':''}>
                            {excelItems.length>0
                            ?excelItems.map((excelMap, index) => (
                                <tr key={index}>
                                    <td>{index + 1 + exclFrstIndx}</td>
                                    <td>{excelMap['LAST NAME']}</td>
                                    <td>{excelMap['FIRST NAME']}</td>
                                    <td>{excelMap['MIDDLE NAME']}</td>
                                    <td>{NumFormat.format(excelMap[' AMOUNT '])}</td>
                                </tr>
                            ))
                            :<tr>
                                <td colSpan={6} className='text-center'>&nbsp;<i hidden={load1}> No Available Data </i>&nbsp;</td>
                            </tr>}
                        </tbody>
                    </table>
                </div>
                
                {exclTotPgs < 2
                    ?<div></div>
                    :<Pagination
                    totalPagesOut = {exclTotPgs}
                    rowCountOut = {exclRowCnt}
                    setslice = {setExcelSlice}
                    totalrowCount = {exclTotRowCnt}
                    numPerPage = {exclNumPerPage}
                />}
            </div>
            
            {/* ----------------- BCS DATA TABLE ----------------- */}
            <div className='card m-2 mt-3'>
                <div className='card-header'>
                    <h3 className='card-title font-weight-bold'><BsIcons.BsDatabase className='mb-1'/> BCS-BCS20</h3>
                </div>

                <div className='card-body p-0 table-responsive'>
                    <table className='table table-head-fixed table-hover text-nowrap'>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Region</th>
                                <th>Location</th>
                                <th>Project Name</th>
                                <th>BIN</th>
                                <th>Common Code</th>
                                <th>Last Name</th>
                                <th>First Name</th>
                                <th>Middle Name</th>
                                <th>Birth Date</th>
                                <th>Account Balance</th>
                                <th>Last Payment</th>
                            </tr>
                        </thead>
                        <tbody id={load2?'loading-table':''}>
                            {bcsItems.length>0
                            ?bcsItems.map((bcs, index) => (
                                <tr key={index} 
                                onClick={(e)=>{srchSubHist(bcs.com_code); handleOpen();}}
                                style={{cursor:'pointer'}}>
                                    <td>{index + 1 + bcsFrstIndx}</td>
                                    <td>{bcs.ro_region}</td>
                                    <td>{bcs.loc_desc}</td>
                                    <td>{bcs.pj_name}</td>
                                    <td>{bcs.bin}</td>
                                    <td>{bcs.com_code}</td>
                                    <td>{bcs.lname}</td>
                                    <td>{bcs.fname}</td>
                                    <td>{bcs.mname}</td>
                                    <td>
                                        {bcs.bdate!=null&&bcs.bdate!=''&&bcs.bdate != undefined
                                        ?new Date(bcs.bdate).toDateString()
                                        :<i>Not Available</i>}
                                    </td>
                                    <td className='text-right'>{NumFormat.format(bcs.baltotal)}</td>
                                    <td>
                                        {bcs.monpdto!=null&&bcs.monpdto!=''&&bcs.monpdto != undefined
                                        ?new Date(bcs.monpdto).toDateString()
                                        :<i>Not Available</i>}
                                    </td>
                                </tr>
                            ))
                            :<tr>
                                <td colSpan={14} className='text-center'>&nbsp;<i hidden={load2}> No Available Data </i>&nbsp;</td>
                            </tr>}
                        </tbody>
                    </table>
                </div>
                
                {bcsTotPgs < 2
                    ?<div></div>
                    :<Pagination
                    totalPagesOut = {bcsTotPgs}
                    rowCountOut = {bcsRowCnt}
                    setslice = {setBcsSlice}
                    totalrowCount = {bcsTotRowCnt}
                    numPerPage = {bcsNumPerPage}
                />}
            </div>
            {/* ----------------- BCS DATA TABLE ----------------- */}

            {/* ----------------- LIS DATA TABLE ----------------- */}
            <div className='card m-2 mt-3'>
                <div className='card-header'>
                    <h3 className='card-title font-weight-bold'><BsIcons.BsDatabase className='mb-1'/> LIS-BCS20</h3>
                </div>

                <div className='card-body p-0 table-responsive'>
                    <table className='table table-head-fixed table-hover text-nowrap'>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Region</th>
                                <th>Location</th>
                                <th>Project Name</th>
                                <th>Common Code</th>
                                <th>Last Name</th>
                                <th>First Name</th>
                                <th>Middle Name</th>
                                <th>Birth Date</th>
                                <th>Address</th>
                            </tr>
                        </thead>
                        <tbody id={load3?'loading-table':''}>
                            {lisItems.length>0
                            ?lisItems.map((lis, index) => (
                                <tr key={index} 
                                onClick={(e)=>{srchSubHist(lis.com_code); handleOpen();}}
                                style={{cursor:'pointer'}}>
                                    <td>{index + 1 + lisFrstIndx}</td>
                                    <td>{lis.ro_region}</td>
                                    <td>{lis.loc_desc}</td>
                                    <td>{lis.pj_name}</td>
                                    <td>{lis.com_code}</td>
                                    <td>{lis.lname}</td>
                                    <td>{lis.fname}</td>
                                    <td>{lis.mname}</td>
                                    <td>
                                        {lis.bdate!=null&&lis.bdate!=''&&lis.bdate != undefined
                                        ?new Date(lis.bdate).toDateString()
                                        :<i>Not Available</i>}    
                                    </td>
                                    <td className='text-wrap'>{lis.address}</td>
                                </tr>
                            ))
                            :<tr>
                                <td colSpan={12} className='text-center'>&nbsp;<i hidden={load3}> No Available Data </i>&nbsp;</td>
                            </tr>}
                        </tbody>
                    </table>
                </div>
                
                {lisTotPgs < 2
                    ?<div></div>
                    :<Pagination
                    totalPagesOut = {lisTotPgs}
                    rowCountOut = {lisRowCnt}
                    setslice = {setLisSlice}
                    totalrowCount = {lisTotRowCnt}
                    numPerPage = {lisNumPerPage}
                />}
            </div>
            {/* ----------------- LIS DATA TABLE ----------------- */}
            
            <MuiViewSubhist
            openViewModal = {openViewModal}
            handleClose = {handleClose}
            subhist = {subhist}
            />

        </section>
    </div>
    </div>
  )
}

export default ExcelSearch