const ExcelJS = require("exceljs");

const exclReportBCS = async( joinedObj, regions ) => {
    // FORMAT NUMBER WITH COMMA
    const NumFormat = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
    // FORMAT NUMBER WITH COMMA

    const workbook = new ExcelJS.Workbook();

    for(const regElmnt of regions){

        const sheet = workbook.addWorksheet(`${regElmnt}`);
        // ============= sheet.properties.defaultRowHeight = 20; =============

        sheet.getCell('A1').font = {
            size: 12,
        };
        sheet.getCell('A1').alignment = { vertical: 'middle', horizontal: 'center' };
        
        sheet.getCell('A2').font = {
            size: 12,
        };
        sheet.getCell('A2').alignment = { vertical: 'middle', horizontal: 'center' };
        
        sheet.getRow(4).font = {
            size: 11,
            bold: true
        };
        
        sheet.getColumn(1).alignment = { vertical: 'middle', horizontal: 'left' };
        sheet.getColumn(2).alignment = { vertical: 'middle', horizontal: 'left' };
        sheet.getColumn(3).alignment = { wrapText: 'true', vertical: 'middle', horizontal: 'left' };
        sheet.getColumn(4).alignment = { wrapText: 'true', vertical: 'middle', horizontal: 'left' };
        sheet.getColumn(5).alignment = { vertical: 'middle', horizontal: 'left' };
        // ============= sheet.getColumn(5).alignment = { wrapText: 'true', vertical: 'middle' }; =============
        sheet.getColumn(6).alignment = { vertical: 'middle', horizontal: 'left' };
        sheet.getColumn(7).alignment = { vertical: 'middle', horizontal: 'left' };
        sheet.getColumn(8).alignment = { vertical: 'middle', horizontal: 'left' };
        sheet.getColumn(9).alignment = { vertical: 'middle', horizontal: 'left' };
        sheet.getColumn(10).alignment = { vertical: 'middle', horizontal: 'left' };
        sheet.getColumn(11).alignment = { vertical: 'middle', horizontal: 'left' };
        sheet.getColumn(12).alignment = { vertical: 'middle', horizontal: 'right' };
        sheet.getColumn(13).alignment = { vertical: 'middle', horizontal: 'right' };

        sheet.getCell('A1').value = 'NATIONAL HOUSING AUTHORITY';
        sheet.getCell('A2').value = `Remittance List - ${regElmnt}`;
        sheet.mergeCells('A1:M1')
        sheet.mergeCells('A2:M2')
        sheet.mergeCells('A3:M3')
        
        sheet.getColumn(2).width = 25;
        sheet.getColumn(3).width = 30;
        sheet.getColumn(4).width = 30;
        sheet.getColumn(5).width = 20;
        sheet.getColumn(6).width = 20;
        sheet.getColumn(7).width = 20;
        sheet.getColumn(8).width = 20;
        sheet.getColumn(9).width = 20;
        sheet.getColumn(10).width = 20;
        sheet.getColumn(11).width = 20;
        sheet.getColumn(12).width = 20;
        sheet.getColumn(13).width = 20;
        
        let numrow = 5;
        let lngth = joinedObj.length;
        let numitem = 0;
        for(let nn=0; nn<lngth; nn++){
            if(joinedObj[nn]['ROREGION'] === regElmnt){

                let bcnlngth = joinedObj[nn].bcs.length;
                let add = numrow + (bcnlngth - 1)
                numitem = numitem + 1

                // EDIT CELLS
                sheet.mergeCells(`A${numrow}:A${add}`)
                sheet.mergeCells(`B${numrow}:B${add}`)
                sheet.mergeCells(`C${numrow}:C${add}`)
                sheet.mergeCells(`D${numrow}:D${add}`)
                sheet.mergeCells(`G${numrow}:G${add}`)
                sheet.mergeCells(`H${numrow}:H${add}`)
                sheet.mergeCells(`I${numrow}:I${add}`)
                sheet.mergeCells(`J${numrow}:J${add}`)
                sheet.mergeCells(`K${numrow}:K${add}`)
                sheet.mergeCells(`L${numrow}:L${add}`)
                sheet.getCell(`A${numrow}`).value = numitem
                sheet.getCell(`G${numrow}`).value = joinedObj[nn]['SERIAL NR']
                sheet.getCell(`H${numrow}`).value = joinedObj[nn]['RANK']
                sheet.getCell(`I${numrow}`).value = joinedObj[nn]['LAST NAME']
                sheet.getCell(`J${numrow}`).value = joinedObj[nn]['FIRST NAME']
                sheet.getCell(`K${numrow}`).value = joinedObj[nn]['MIDDLE NAME']
                sheet.getCell(`L${numrow}`).value = NumFormat.format(joinedObj[nn][' AMOUNT '])
                // EDIT CELLS

                // POSITIONING - ROW NUMBER
                for(let bcn=0; bcn<bcnlngth; bcn++){
                    sheet.getCell(`B${numrow}`).value = joinedObj[nn].bcs[bcn]['ro_region']
                    sheet.getCell(`C${numrow}`).value = joinedObj[nn].bcs[bcn]['loc_desc']
                    sheet.getCell(`D${numrow}`).value = joinedObj[nn].bcs[bcn]['pj_name']
                    sheet.getCell(`E${numrow}`).value = joinedObj[nn].bcs[bcn]['bin']
                    sheet.getCell(`F${numrow}`).value = joinedObj[nn].bcs[bcn]['com_code']
                    sheet.getCell(`M${numrow}`).value = NumFormat.format(joinedObj[nn].bcs[bcn]['baltotal'])
                    numrow = numrow + 1
                }
                // POSITIONING - ROW NUMBER
            }
            else {
                // Do nothing
            }
        }
        
        sheet.addTable({
            name: 'MyTable',
            ref: 'A4',
            headerRow: true,
            totalsRow: false,
            style: {
                theme: 'None',
                showRowStripes: true,
            },
            columns: [
                {name: 'No.', filterButton: true},
                {name: 'Region', filterButton: true},
                {name: 'Location', filterButton: true},
                {name: 'Project Name', filterButton: true},
                {name: 'BIN', filterButton: true},
                {name: 'Common Code', filterButton: true},
                {name: 'Serial Number', filterButton: true},
                {name: 'Rank', filterButton: true},
                {name: 'Last Name', filterButton: true},
                {name: 'First Name', filterButton: true},
                {name: 'Middle Name', filterButton: true},
                {name: 'Amount', filterButton: true},
                {name: 'Total Balance', filterButton: true},
            ],
            rows: [],
        });
    
    }

    workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = "RemittanceList-Report.xlsx";
        anchor.click();
        window.URL.revokeObjectURL(url);
    });

    return "success"
}

export{
    exclReportBCS
};