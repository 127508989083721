const insrt_usr = async(
    txtid,lname,fname,mname,
    email,empno,accessLevel,region,
    district,hash,
    url_str,url_origin) => {
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    headers.append("Origin", `${url_origin}`);
    try{
        const response = await fetch(`${url_str}/insertUser`, { 
            mode: "cors",
            method: "POST",
            headers: headers,
            credentials: "include",
            body: JSON.stringify({
                userid: txtid,
                lname: lname,
                fname: fname,
                mname: mname,
                email: email,
                empno: empno,
                access: accessLevel,
                region: region,
                district: district,
                password: hash,
            }),
        });
        
        const data = await response.json();
        
        if (data === 'Success') {
          return "Success"
        } else {
          return "Failed";
        }
    }
    catch (err) {
        console.log(err)
    }
}

const updte_usr = async(
    userIdEdt,lnameEdt,fnameEdt,mnameEdt,
    emailEdt,empnoEdt,accessLevelEdt,regionEdt,
    districtEdt,statusEdt,
    url_str,url_origin) => {
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    headers.append("Origin", `${url_origin}`);
    try{
        const response = await fetch(`${url_str}/updateUser`, { 
            mode: "cors",
            method: "POST",
            headers: headers,
            credentials: "include",
            body: JSON.stringify({
                userid: userIdEdt,
                lname: lnameEdt,
                fname: fnameEdt,
                mname: mnameEdt,
                email: emailEdt,
                empno: empnoEdt,
                access: accessLevelEdt,
                region: regionEdt,
                district: districtEdt,
                status: statusEdt,
            }),
        });
        
        const data = await response.json();
        
        if (data === 'Success') {
          return "Success"
        } else {
          return "Failed";
        }
    }
    catch (err) {
        console.log(err)
    }
}

const updte_usr_email = async(userIdEdt,emailEdt,url_str,url_origin) => {
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    headers.append("Origin", `${url_origin}`);
    try{
        const response = await fetch(`${url_str}/updateEmail`, { 
            mode: "cors",
            method: "POST",
            headers: headers,
            credentials: "include",
            body: JSON.stringify({
                userid: userIdEdt,
                email: emailEdt,
            }),
        });
        
        const data = await response.json();
        
        if (data === 'Success') {
          return "Success"
        } else {
          return "Failed";
        }
    }
    catch (err) {
        console.log(err)
    }
}

const updte_usr_pass = async(userIdEdt,passEdt,url_str,url_origin) => {
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    headers.append("Origin", `${url_origin}`);
    try{
        const response = await fetch(`${url_str}/updatePass`, { 
            mode: "cors",
            method: "POST",
            headers: headers,
            credentials: "include",
            body: JSON.stringify({
                userid: userIdEdt,
                password: passEdt,
            }),
        });
        
        const data = await response.json();
        
        if (data === 'Success') {
          return "Success"
        } else {
          return "Failed";
        }
    }
    catch (err) {
        console.log(err)
    }
}

const rst_usr_pass = async(txtid,pass,hash,email,fname,url_str,url_origin) => {
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    headers.append("Origin", `${url_origin}`);
    try{
        const response = await fetch(`${url_str}/resetpass`, { 
            mode: "cors",
            method: "POST",
            headers: headers,
            credentials: "include",
            body: JSON.stringify({
                id: txtid,
                pass: pass,
                hash: hash,
                email: email,
                fname: fname,
            }),
        });

        const data = await response.json();
        
        if (data === 'Success') {
          return "success"
        } else {
          return "failed";
        }
    }
    catch (err) {
        console.log(err)
    }
}

export{
    insrt_usr,
    updte_usr,
    updte_usr_email,
    updte_usr_pass,
    rst_usr_pass,
};