import {useState} from 'react';
import './Login.css';
import {BsArrowLeft} from "react-icons/bs";
import * as FaIcons  from 'react-icons/fa';
import * as BsIcons from "react-icons/bs";
import deptLogo from './dist/img/deptLogo.png';
import nhaLogo from './dist/img/nha-logo.png';
// mui
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grow from '@mui/material/Grow';
// PLUGINS
import bcrypt from 'bcryptjs'
import { useNavigate } from 'react-router-dom';
import handleDecrypt from './functions/fx_dcrpt';
import {rst_usr_pass} from './functions/fx_user';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

const Login = ({ url_str, url_origin }) => {
  // mui
  const labelStyle = {
    fontWeight: 'normal',
  };
  
  // NAVIGATE
  const navigate = useNavigate();
  // NAVIGATE

  // HEADERS
  let headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('Accept', 'application/json');
  headers.append('Origin', `${url_origin}`);
  // HEADERS

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  
  const [emailfg, setEmailfg] = useState('');
  const [empno, setEmpno] = useState('');

  const [currentStep, setCurrentStep] = useState(1);

  const signin = async(e) => {
    e.preventDefault();
    document.getElementById('icnLoginRotate').hidden = false;
    document.getElementById('icnLogin').hidden = true;
    try {
      const response = await fetch(`${url_str}/loginpost`, { 
        mode: 'cors',
        method: 'POST',
        headers: headers,
        credentials: 'include',
        body: JSON.stringify({
          email: email,
        }),
      });
    
      const encrptdata = await response.json();
      const dataValue = handleDecrypt(encrptdata);
      const data = await JSON.parse(dataValue);

      if(data.rows.length === 1){
        localStorage.clear()
        // COMPARE PASSWORD
        bcrypt.compare(password, data.rows[0].user_password, function(err, result) {
          if(result){
            let usernameSession = data.rows[0].user_email;
            let useridSession = data.rows[0].user_id;
            logSession(usernameSession, useridSession)
          }
          else{
            console.log(err)
            toast.error('Wrong Password.')
            document.getElementById('icnLoginRotate').hidden = true;
            document.getElementById('icnLogin').hidden = false;
          }
        });
        // COMPARE PASSWORD
      }
      else{
        toast.error('Wrong Email Account.')
        document.getElementById('icnLoginRotate').hidden = true;
        document.getElementById('icnLogin').hidden = false;
      }
    }
    catch (err) {
      console.log(err);
      toast.error('Oops! Something went wrong. Please try again.')
      document.getElementById('icnLoginRotate').hidden = true;
      document.getElementById('icnLogin').hidden = false;
    }
  }
  
  const logSession = async(usernameSession, useridSession) => {
    try {
      const response = await fetch(`${url_str}/loginsessionset`, { 
        mode: 'cors',
        method: 'POST',
        headers: headers,
        credentials: 'include',
        body: JSON.stringify({
          email: usernameSession,
        }),
      });
      if (response.status === 200) {
        // document.getElementById('icnLoginRotate').hidden = true;
        // document.getElementById('icnLogin').hidden = false;
        // let act = "User Logged In"
        // audtlog.log_pstd(
        //     act,
        //     useridSession,
        //     url_str,
        //     url_origin
        // )
        return  navigate('/name_srch');
      }
      else{
        toast.error('Login Failed.')
        document.getElementById('icnLoginRotate').hidden = true;
        document.getElementById('icnLogin').hidden = false;
      }
    }
    catch (err) {
      console.log(err);
      toast.error('Oops! Something went wrong. Please try again.')
      document.getElementById('icnLoginRotate').hidden = true;
      document.getElementById('icnLogin').hidden = false;
    }
  } 
  
  const forgtpass = async(e) => {
    e.preventDefault();
    document.getElementById('icnSubmtRotate').hidden = false;
    document.getElementById('icnSubmt').hidden = true;
    try {
        const response = await fetch(`${url_str}/forgotpass`, { 
          mode: 'cors',
          method: 'POST',
          headers: headers,
          credentials: 'include',
          body: JSON.stringify({
            empno: empno,
            email: emailfg,
          }),
        });

        const encrptdata = await response.json();
        const dataValue = handleDecrypt(encrptdata);
        const data = await JSON.parse(dataValue);

        if(data.rows.length === 1){
            logResetPass(data.rows[0].user_id, data.rows[0].user_fname)
        }
        else{
            toast.error('Wrong Employee Number or Email Account.')
            document.getElementById('icnSubmtRotate').hidden = true;
            document.getElementById('icnSubmt').hidden = false;
        }
    }
    catch (err) {
        console.log(err);
        toast.error('Oops! Something went wrong. Please try again.')
        document.getElementById('icnSubmtRotate').hidden = true;
        document.getElementById('icnSubmt').hidden = false;
    }
}

const logResetPass = async(id, fname) => {
    let randomstring = Math.random().toString(24).slice(-8);
    bcrypt.hash(randomstring, 10, function(err, hash) {
        let res = rst_usr_pass(id,randomstring,hash,emailfg,fname,url_str,url_origin)
        res.then((val) => {
            if (val === "success") {
                toast.success('Your password has been successfully reset.')
                setEmpno('')
                setEmailfg('')
                setCurrentStep(currentStep - 1)
                document.getElementById('icnSubmtRotate').hidden = true;
                document.getElementById('icnSubmt').hidden = false;
            } else {
                toast.error('Failed to reset user password.')
                document.getElementById('icnSubmtRotate').hidden = true;
                document.getElementById('icnSubmt').hidden = false;
            }
        })
    })
}

  return (
    <>
      <ToastContainer
        position='top-right'
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
        theme='light'
      />
      <div className='login-page bg-bluelines'>
        <div className="login-box">
          <div className="card" style={{width: "400px", height: '450px'}}>
            <div className="card-body pt-0">
              {currentStep === 1 && (
                <Grow in={currentStep === 1}>
                  <form onSubmit={signin} className='login-form'>
                    <div style={{lineHeight: "10px", textAlign: 'center'}}>
                      <img src={nhaLogo} className="elevation-2 img-circle" alt="COSDD Logo" style={{width: "50px"}}/>
                      <h5 className="login-title mb-0">
                        <b>NATIONAL HOUSING AUTHORITY</b>
                      </h5>
                      <h5>Remittance List</h5>
                    </div>
                    <br></br>
                    <TextField className="mb-3" id="loginEmail" 
                    onChange={(e)=>{setEmail(e.target.value)}}
                    label="Email Address" required
                    variant="outlined" size="small" fullWidth 
                    InputLabelProps={{ style: labelStyle }} type="text"/>

                    <TextField id="outlined-basic" label="Password" 
                    onChange={(e)=>{setPassword(e.target.value)}}
                    variant="outlined" required
                    size="small" fullWidth InputLabelProps={{ style: labelStyle }} 
                    type="password"/>
                    <small className='float-right text-muted'>
                      Forgot your password? click <a href='#' onClick={() => setCurrentStep(currentStep + 1)}>here</a>
                    </small>
                    
                    {/* <a href="#" className='a-link'>Forgot Password?</a> */}
                    <Button type='submit' variant="contained" color="primary"
                    className='w-100 mt-4' size="large">
                      <span id='icnLogin'>LOGIN</span>
                      <span id='icnLoginRotate' hidden>
                        <FaIcons.FaSpinner className='rotating'/>
                      </span>
                    </Button>
                  </form>
                </Grow>
              )}
        
              {currentStep === 2 && (
                <Grow in={currentStep === 2}>
                  <form onSubmit={forgtpass} className='login-form'>
                    <div className='text-left mb-4'>
                      {currentStep === 2 && (
                          <small>
                            <a href='#' onClick={() => setCurrentStep(currentStep - 1)}>
                              <BsArrowLeft className='mb-1'/> back to Login
                            </a>
                          </small>
                      )}
                    </div>
                    <div className='text-left mb-5' style={{lineHeight: "10px"}}>
                      <h4 className='font-weight-bold'>Forgot Password?</h4>
                      <small>Enter credentials to change password</small>
                    </div>

                    <TextField className="mb-3" id="outlined-basic" label="Employee No." 
                    variant="outlined" size="small" required
                    fullWidth InputLabelProps={{ style: labelStyle }} type="text"
                    value={empno}
                    onChange={(e)=>{setEmpno(e.target.value)}}
                    />

                    <TextField className="mb-3" id="outlined-basic" label="Email Address" 
                    variant="outlined" size="small" required
                    fullWidth InputLabelProps={{ style: labelStyle }} type="text"
                    value={emailfg}
                    onChange={(e)=>{setEmailfg(e.target.value)}}
                    />

                    <Button type='submit' variant="contained" color="primary" 
                    className='w-100 mt-4' size="large">
                      <span id='icnSubmt'>SUBMIT</span>
                      <span id='icnSubmtRotate' hidden>
                        <FaIcons.FaSpinner className='rotating'/>
                      </span>
                    </Button>
                  </form>
                </Grow>
              )}
            </div>
          </div>
        </div>
        
        <div className='mt-5 text-white'>
          <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
            <div className='ml-4'>
              <img src={deptLogo} className="img-circle" alt="nha Logo" style={{width: "50px"}}/>
            </div>
            <div className='ml-2 text-center pt-2' style={{lineHeight:'10px'}}>
              <p>Corporate Operation and System Development Department</p> 
              <p><BsIcons.BsEnvelopeFill/> cosdd.helpdesk@nha.gov.ph | <BsIcons.BsTelephoneFill/> (02) 8426-4703 loc 8081</p> 
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
