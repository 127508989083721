import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import * as BsIcons from "react-icons/bs";
import Grow from '@mui/material/Grow';

const MuiViewSubhist = ({ openViewModal, handleClose, subhist }) => {

// MUI MODAL STYLE
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    boxShadow: 24,
    paddingTop: 1,
    paddingBottom: 1,
    borderRadius: '10px',
};
// MUI MODAL STYLE

    return (
        <Modal
            open={openViewModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div className='card-header'>
                    <h3 className='card-title font-weight-bold'><BsIcons.BsDatabase className='mb-1'/> SUBHIST</h3>
                </div>
                <div className="card-body table-responsive p-0">
                    <table className="table table-head-fixed table-hover text-wrap">
                        <thead>
                            <tr>
                                <th className='col-6 pt-4'>NAME</th>
                                <th className='col-4 pt-4'>BIRTHDAY</th>
                                <th className='col-2 pt-4'>SEX</th>
                            </tr>
                        </thead>
                        <Grow in={true}>
                            <tbody>
                            {subhist.length > 0 
                            ?subhist.map((dt, index) => (
                                <tr key={index}>
                                    <td>
                                        {dt.FNAME != undefined || dt.LNAME != undefined
                                        ? dt.FNAME + ' ' + dt.LNAME
                                        : <i>Not Available</i>}
                                    </td>
                                    <td>
                                        {dt.BDATE!=null&&dt.BDATE!=''&&dt.BDATE != undefined
                                        ? new Date(dt.BDATE).toDateString()
                                        : <i>Not Available</i>}
                                    </td>
                                    <td>
                                        {dt.SEX != undefined
                                        ? dt.SEX != '1'?'Female':'Male'
                                        : <i>Not Available</i>}
                                    </td>
                                </tr>
                            ))
                            :
                            <tr>
                                <td colSpan={3} className='text-center'>
                                    &nbsp;<i>Not Available</i>&nbsp;
                                </td>
                            </tr>}
                            </tbody>
                        </Grow>
                    </table>
                </div>
            </Box>
        </Modal>
    )
}

export default MuiViewSubhist