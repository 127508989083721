import React from 'react'
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import * as BsIcons from "react-icons/bs";
// mui
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

const AuditTrail = ({ url_str, url_origin }) => {

const labelStyle = {
    fontWeight: 'normal',
};
return (
    <div>
    <Sidebar/>
    <Header url_str={url_str} url_origin={url_origin} />

    <div className="content-wrapper pl-2 pr-2 pb-5">
        <section className="content-header">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-6">
                    <h1>Audit Trail</h1>
                    </div>
                </div>
            </div>
        </section>
        <section className="content">
            <div className="card ml-2 mr-2">
                <div className="card-body">
                    <div className='row'>
                        <div className='col-sm-10'>
                            <TextField label="" variant="outlined" placeholder="Search here" size="small" InputLabelProps={{style: labelStyle}} fullWidth />
                        </div>
                        <div className='col-sm-2'>
                            <Button className='h-100' variant="contained" disableElevation fullWidth>
                                <BsIcons.BsSearch className='mb-1'/>&nbsp;Search 
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='card m-2 mt-3'>
                <div className='card-header'>
                    <h3 className='card-title font-weight-bold'><BsIcons.BsBook className='mb-1'/> ACTIVITY LOG</h3>
                </div>

                <div className='card-body p-0 table-responsive'>
                    <table className='table table-head-fixed table-hover text-nowrap'>
                        <thead>
                            <tr>
                                <th>BIN</th>
                                <th>First Name</th>
                                <th>Middle Name</th>
                                <th>Last Name</th>
                                <th>Birth Date</th>
                                <th>Serial No.</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>000000</td>
                                <td>Joehara</td>
                                <td>La</td>
                                <td>Rodriguez</td>
                                <td>May 10, 1998</td>
                                <td>101010</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    </div>
    </div>
  )
}

export default AuditTrail