import { useState } from "react";

const Pagination = ({ totalPagesOut, rowCountOut, setslice, totalrowCount, numPerPage }) => {
  
  const [currentPage, setCurrentPage] = useState(1)
  const totalPages = totalPagesOut
  const rowCount = rowCountOut
  const last = (currentPage * numPerPage);
  const first = last - numPerPage;
  const firstIndex = first
  const lastIndex = first + (rowCount)
  const pageNumbers = [];

  // start of page conditions
  if(currentPage < 5){
    for (let i = 2; i < totalPages; i++) {
      pageNumbers.push(i);
      if(i >= 5){
        break;
      }
    }
  }
  else {
    for (let ii = currentPage - 2; ii < totalPages; ii++) {
      pageNumbers.push(ii);
      if(ii >= totalPages - 1){
        if(ii >= currentPage + 2){
          break;
        }
        break;
      }
      else{
        if(ii >= currentPage + 2){
          break;
        }
      }
    }
  }
  // end of page conditions

  const page = (number) => {
    // Fetch/Refresh Table;
    setslice(number)
    //Set current page
    setCurrentPage(number)
  }

  return (
    <div className='p-1 pt-3 ml-1 mr-1 row justify-content-between pagination'>
      <div className='pl-1 p-1'>
        <button
          className={`${currentPage === 1 
              ? "btn btn-default mr-1 active" 
              : "btn btn-default mr-1"}`}
          id='btn-sidebar-custom'
          onClick={(e) => {page(1)}}>
          1
        </button>

        {currentPage >= 5 ? <a style={{width: "28px", textAlign: "center", padding: "8px"}}>...</a> : ''}
        
        {/* page between */}
        {pageNumbers.map((number, index) => {
            return <button
            key={index}
            onClick={(e) => {page(number)}}
            id='btn-sidebar-custom'
            className={`${currentPage === number 
                ? "btn btn-default mr-1 active" 
                : "btn btn-default mr-1"}`}>
                {number}
            </button>
        })}

        {/* ellipsis */}
        {currentPage <= totalPages - 4 && totalPages != 6 && totalPages != 5
        ? <a style={{width: "28px", textAlign: "center", padding: "8px"}}>...</a>
        : ''}
        {currentPage == 4 && totalPages == 7? <a style={{width: "28px", textAlign: "center", padding: "8px"}}>...</a> : null}

        <button
          className={`${currentPage === totalPages 
              ? "btn btn-default mr-1 active" 
              : "btn btn-default mr-1"}`}
          id='btn-sidebar-custom'
          onClick={(e) => {page(totalPages)}}>
            {totalPages}
        </button>

      </div>
      <div className='pr-1 p-1'>
          Showing {firstIndex + 1} to {lastIndex} out of {totalrowCount} result(s)
      </div>
    </div>
  )
}

export default Pagination